import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getCateringMealsOrderDetails, getOrderDetails, resetCheckoutEbtState, saveOrderAnalytics } from "redux/actions";
import { Link } from "react-router-dom";
import Moment from "moment";
import "./order-confirmation.scss";
import "./order-table.scss";
import { getLocalStorageValue } from "config/helper";

const OrderConfirmation = () => {
  const dispatch = useDispatch();
  const [readyOrderDetail, setReadyOrderDetails] = useState(false);
  const { savePlaceOrder, ebtPaymentResponse, paymentTransactionId, paymentTransactionType } = useSelector(({ checkout }) => checkout);
  const { orderdetailsData, orderdetailsDataLoading } = useSelector(({ customer }) => customer);
  const clientId = getLocalStorageValue("RSAclient-id");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");

  const {
    order_id,
    meal_order_id,
    order_number,
    customer_email,
    shipping_date,
    shipping_day,
    shipping_time,
    order_placed_on,
    customer_first_name,
    customer_last_name,
    transaction_id,
    pickup_location: {
      AddressLine1 = "",
      AddressLine2 = "",
      City = "",
      StateName = "",
      StoreEmail = "",
      StorePhoneNumber = "",
      ZipCode = "",
    } = {},
  } = savePlaceOrder || {};

  const headerSectionData = useSelector(({ common }) => common.commonDetailsData.header_section);
  const copyRightSectionData = useSelector(({ common }) => common.commonDetailsData.footer_section);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      AppName: isUserLoggedIn ? "shop" : "",
      Version: isUserLoggedIn ? "1" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    setReadyOrderDetails(true);
    if (order_id) {
      body.order_id = order_id;
      dispatch(getOrderDetails(body));
    } else if (meal_order_id) {
      body.meal_order_id = meal_order_id;
      dispatch(getCateringMealsOrderDetails(body));
    }
  }, [dispatch, clientId, storeId, isUserLoggedIn, memberNumber, userToken, order_id, meal_order_id]);

  const formatToRFC3339 = (inputDate) => new Date(inputDate.replace(/-/g, "/")).toISOString();

  useEffect(() => {
    if (orderdetailsData && readyOrderDetail && !orderdetailsDataLoading) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        member_number: orderdetailsData.member_number || "",
        order_id: order_id || "",
        order_date: formatToRFC3339(orderdetailsData?.order_placed_on) || "",
        items: orderdetailsData.order_item_list.map(item => ({
          upc: item.sku,
          qty: item.qty,
          unit_price: item.price,
          total_item_price_after_discount: item.final_total,
          discount_amount: item.coupon_discount
        }))
      };
      dispatch(saveOrderAnalytics(body));
    }
  }, [orderdetailsData, readyOrderDetail, orderdetailsDataLoading, dispatch, clientId, storeId, order_id]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("last_new_order_id");
      dispatch(resetCheckoutEbtState());
    };
  }, [dispatch]);

  const dateFormat = (inputDate, format, moment) => {
    if (moment) {
      return Moment(inputDate).format("MM-DD-YYYY");
    } else {
      const date = new Date(inputDate);
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      format = format.replace("MM", month.toString().padStart(2, "0"));

      if (format.indexOf("yyyy") > -1) {
        format = format.replace("yyyy", year.toString());
      } else if (format.indexOf("yy") > -1) {
        format = format.replace("yy", year.toString().substr(2, 2));
      }
      format = format.replace("dd", day.toString().padStart(2, "0"));

      return format;
    }
  };

  const formatPhoneNumber = (str) => {
    const cleaned = ("" + str).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null;
  };

  const { ebt_card_balance_info, ebt_initiate_tran_id } = (ebtPaymentResponse && ebtPaymentResponse.data) || {};

  return (
    <div className="order-confirmation-wrapper">
      <div className="order-confirmation">
        <div className="confirmation-header">
          <h2>Thank you for your order</h2>
        </div>

        <table className="shop_table my_account_orders">
          <tbody className="confirmation-body">
            <tr className="order">
              <td className="order-number" data-title="" colSpan={1}>
                <img
                  src={headerSectionData.rsa_client_logo}
                  alt={headerSectionData.image_alt_text}
                  className="logo-width-100"
                />
              </td>
              <td className="order-actions" data-title="" colSpan={3}>
                <img
                  src={copyRightSectionData.powered_by}
                  alt="PowerdByImage"
                />
              </td>
            </tr>

            <tr className="order">
              <td className="order-number" data-title="" colSpan={1}>
                <strong>
                  {customer_first_name} {customer_last_name},
                </strong>{" "}
                Your order has been received.
                <br />
                You will receive an email confirmation shortly on{" "}
                <strong> {customer_email}</strong>
              </td>
              <td className="order-actions" data-title="" colSpan={3}>
                <h4>Your pickup location:</h4>
                <p>
                  {AddressLine1} {AddressLine2}, {City}, {StateName},{" "}
                  {ZipCode}
                  <br />
                </p>
              </td>
            </tr>

            <tr className="order">
              <td className="order-number" data-title="" colSpan={1}>
                <p className="order-numer">
                  Order Placed{" "}
                  <strong>

                    {dateFormat(order_placed_on.split(" ")[0], "MM-dd-yyyy")}
                  </strong>
                </p>
                <p className="order-number">
                  <strong>Order number is: {order_number}</strong>
                </p>
              </td>
              <td className="order-actions" data-title="" colSpan={3}>
                <h4>Pickup Date:</h4>
                <p>
                  {shipping_day},{" "}
                  {dateFormat(shipping_date, "MM-dd-yyyy", "moment")}
                  <br />
                  {shipping_time}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        {ebt_initiate_tran_id && (
          <p>
            <strong>
              Your EBT Payment Transaction ID is : {ebt_initiate_tran_id}
            </strong>
          </p>
        )}
        {ebt_card_balance_info && <p>{ebt_card_balance_info}</p>}
        {transaction_id && (
          <p>Your Payment Transaction ID is : {transaction_id}</p>
        )}
        {paymentTransactionId && paymentTransactionType === "authorize.net" && (
          <p>Your Authorize Payment Transaction ID is : {paymentTransactionId}</p>
        )}
        {paymentTransactionId && paymentTransactionType === "stripe" && (
          <p>Your Stripe Payment Token ID is : {paymentTransactionId}</p>
        )}
        <br />
        <div className="confirmation-body">
          <span className="order-page-link">
            You can check status of this order in your
            <Link to="/myaccount"> account.</Link>
          </span>
          <span className="email-us">
            Questions? Call {formatPhoneNumber(StorePhoneNumber)} or
          </span>
          <a href={`mailto:${StoreEmail}`} target="_blank" rel="noreferrer">
            Email us
          </a>
          <span className="back-to-home-btn">
            <Link to="/">Back to home</Link>
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderConfirmation;
