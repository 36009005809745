import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoadingImage from "../../assets/icons/load.gif";

import {
  addToCart,
  getRemoveProductToCartList,
  editProductToCartList,
  addToWishlist,
  updateProductToCartList,
  mediaAnalyticsClick,
  mediaAnalyticsImpression,
} from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { getLocalStorageValue } from "config/helper";

import AddToMyListTheme2 from "components/AddToMyListTheme2";
import "./product-card-theme-2.scss";

const ProductCardTheme2 = ({ product }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const bannerRef = useRef(null);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const clientId = getLocalStorageValue("RSAclient-id");
  const dispatchedCitrusAdIds = new Set();
  const dispatchedClickedCitrusAdIds = new Set();
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const productId = product.product_id;
  const {
    productCartList,
    addProductToCartListLoading,
    wishListItemsLoading,
    updateProductToCartListLoading,
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const addedProcduct =
    productCartList &&
    productCartList.find((product) => product.product_id === productId);
  const cartProductQuantity = addedProcduct && addedProcduct.qty;
  const [productQuantity, setProductQuantity] = useState(
    +cartProductQuantity || 1
  );
  const [qtyUpdatingProduct, setqtyUpdatingProduct] = useState(0);
  const itemId = addedProcduct && addedProcduct.item_id;
  const [isAddingProductToCart, setAddingProductToCart] = useState(false);

  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);

  const { allow_ecom_cart_and_checkout, allow_ecom_shown_price } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  const handleAddToCart = () => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }

    if (product.is_mealkit == "0") {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        product_id: productId,
        qty: productQuantity,
        member_number: memberNumber,
        user_token: userToken,
        AppName: "shop",
        DeviceType: "web",
        Version: "1",
        product_note: "",
        custom_options_arr: "",
      };

      setAddingProductToCart(true);
      isUserLoggedIn &&
        dispatch(addToCart(body)).then(() => {
          setAddingProductToCart(false);
        });
    } else {
      navigate(
        `/catering-product-description/${product.department_slug}/${product.slug}`
      );
    }
  };

  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
    handleUpdateProductToCart(productQuantity + 1, productId, itemId);
  };

  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
      handleUpdateProductToCart(productQuantity - 1, productId, itemId);
    } else {
      addedProcduct && handleRemoveProductToCart(itemId);
      setProductQuantity(1);
    }
  };

  const handleAddToWishlist = (list_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id: product.product_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      qty: productQuantity,
      list_id,
    };

    dispatch(addToWishlist(body)).then(() => {
      setShowMyListDropdown(false);
    });
  };

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      member_number: memberNumber,
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
    };
    dispatch(getRemoveProductToCartList(body));
  };

  const handleUpdateProductToCart = (quantity, product_id, item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      item_id,
      qty: quantity,
      member_number: memberNumber,
      user_token: userToken,
      AppName: "shop",
      DeviceType: "web",
      Version: "1",
      product_note: "",
      page_name: 'product_list',
      custom_options_arr: "",
    };
    setqtyUpdatingProduct(product_id);
    quantity >= 1 && dispatch(editProductToCartList(body));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          const citrusAdId = entry.target.getAttribute('data-citrus-ad-id');
          if (citrusAdId && !dispatchedCitrusAdIds.has(citrusAdId)) {
            dispatch(mediaAnalyticsImpression(citrusAdId, clientId, isUserLoggedIn ? memberNumber : ""));
            dispatchedCitrusAdIds.add(citrusAdId);
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (bannerRef.current) {
      observer.observe(bannerRef.current);
    }

    return () => {
      if (bannerRef.current) {
        observer.unobserve(bannerRef.current);
      }
    };
  }, [dispatch]); // eslint-disable-line

  const handleClick = (event) => {
    event.preventDefault();
    const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
    if (citrusAdId && !dispatchedClickedCitrusAdIds.has(citrusAdId)) {
      dispatch(mediaAnalyticsClick(citrusAdId, clientId, isUserLoggedIn ? memberNumber : ""));
      dispatchedClickedCitrusAdIds.add(citrusAdId);
    }
    if (product.is_mealkit == "0") {
      navigate(`/product-description/${product.department_slug}/${product.slug}`);
    } else {
      navigate(
        `/catering-product-description/${product.department_slug}/${product.slug}`
      );
    }
  };
  const handleMouseDown = (event) => {
    if (event.button === 1 || event.button === 2) {
      const citrusAdId = event.currentTarget.getAttribute('data-citrus-ad-id');
      if (citrusAdId && !dispatchedClickedCitrusAdIds.has(citrusAdId)) {
        dispatch(mediaAnalyticsClick(citrusAdId, clientId, isUserLoggedIn ? memberNumber : ""));
        dispatchedClickedCitrusAdIds.add(citrusAdId);
      }
    }
  };


  return (
    <>
      <div ref={bannerRef} data-citrus-ad-id={product.rsamediaid} className="product-col-xl-4" key={product.id}>
        <div className="deal-all-product">
          <article className="deal-all-product-main-wrapper">
            <div className="deal-right-product-wrap">
              <div className="deal-right-product-main-wrap">
                <div className="offer-tag-quickview">
                  <div className="offer-coupon-warp">
                    {+product.has_coupon > 0 && (
                      <ul className="offer-product-tag">
                        {parseFloat(product.digital_coupon_amount) > 0 && (
                          <li className="product-tag">
                            Digital Coupon:
                            {product.is_discount_percentage === "0" &&
                              product.digital_coupon_amount > 0 ? (
                              <span>
                                {" "}
                                {product.is_discount_percentage === "0"
                                  ? "$"
                                  : ""}
                                {`${product.digital_coupon_amount}`}
                                {product.is_discount_percentage === "1"
                                  ? "%"
                                  : ""}
                              </span>
                            ) : (
                              <span>
                                {product.is_discount_percentage === "1" ? (
                                  <>
                                    {+product.digital_coupon_amount === 100 ? (
                                      <span>{" FREE* "}</span>
                                    ) : (
                                      <span>
                                        {" "}
                                        {product.is_discount_percentage === "0"
                                          ? "$"
                                          : ""}
                                        {`${product.digital_coupon_amount}`}
                                        {product.is_discount_percentage === "1"
                                          ? "%"
                                          : ""}
                                      </span>
                                    )}
                                  </>
                                ) : (
                                  ""
                                )}
                              </span>
                            )}
                          </li>
                        )}
                      </ul>
                    )}
                    {product &&
                      product.coupon_used_time > 1 &&
                      +product.has_coupon > 0 ? (
                      <div className="coupon-used-time-ribbon">
                        <span>{product.coupon_used_time}X</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {
                    +product.is_onsale > 0 ?
                      <div className="product-card-onsale-lable">On&nbsp;Sale</div>
                      : ""
                  }
                </div>
                <div className="deal-right-product-img">
                  <Link
                    onMouseDown={handleMouseDown}
                    data-citrus-ad-id={product.rsamediaid}
                    to={product.is_mealkit == "0" ? (`/product-description/${product.department_slug}/${product.slug}`) : (`/catering-product-description/${product.department_slug}/${product.slug}`)}
                  >
                    <img src={product.image} alt="ProductImg" onClick={handleClick} data-citrus-ad-id={product.rsamediaid} />
                  </Link>
                  <span>
                    <AddToMyListTheme2
                      isShowMyListDropdown={isShowMyListDropdown}
                      isShowNewListModal={isShowNewListModal}
                      setShowNewListModal={setShowNewListModal}
                      setShowMyListDropdown={setShowMyListDropdown}
                      title=""
                      handleApply={handleAddToWishlist}
                      loading={wishListItemsLoading}
                      isShowIcon={true}
                      rsaMedaiId={product?.rsamediaid ?? ""}
                    />
                  </span>
                </div>
                <Link
                  onContextMenu={handleMouseDown}
                  data-citrus-ad-id={product.rsamediaid}
                  to={product.is_mealkit == "0" ? (`/product-description/${product.department_slug}/${product.slug}`) : (`/catering-product-description/${product.department_slug}/${product.slug}`)}
                >
                  <h2 className="product-title" onClick={handleClick} data-citrus-ad-id={product.rsamediaid}>{product.name}</h2>
                </Link>
                <span className="upc">UPC: {product?.sku}</span>
                <div className="deal-right-product-caption">
                  <div className="card-price-title">
                    {/* title remove from here */}
                    {allow_ecom_shown_price === "1" &&
                      <>
                        <div className="product-price-shipping">
                          {product.final_price > 0 ? (
                            <>
                              <span className="price">{product?.price5}</span>
                              <span
                                class={`regular-price ${product.final_price > 0 ? "hidden" : ""
                                  }`}
                              >
                                {product?.price1}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="price">{product.price1}</span>
                            </>
                          )}
                        </div>
                        {/* price3 added in - product card info added by Madhu - 26/07/2024 */}
                        {/* price3 added in - product card info added by ks - 06/03/2025 */}
                        <br />
                        <span className="product-price3">
                          {product.price3 !== "" ? product.price3 : ""}
                        </span>
                      </>
                    }
                  </div>
                  {isUserLoggedIn && addedProcduct ? (
                    <div className="increment-decrement-qty">
                      {updateProductToCartListLoading &&
                        qtyUpdatingProduct === product.product_id ? (
                        <div className="load-icon">
                          <img src={LoadingImage} alt="Loading..." />
                        </div>
                      ) : (
                        <>
                          <button
                            onClick={handleDecrementCount}
                            title="Decrease Qty"
                          >
                            -
                          </button>
                          <span>
                            {(addedProcduct && addedProcduct.qty) ||
                              productQuantity}
                          </span>
                          <button
                            onClick={handleIncrementCount}
                            title="Increase Qty"
                          >
                            +
                          </button>
                        </>
                      )}
                    </div>
                  ) : (
                    <>
                      {isUserLoggedIn &&
                        addProductToCartListLoading &&
                        isAddingProductToCart ? (
                        <div className="load-icon">
                          <img src={LoadingImage} alt="Loading..." />
                        </div>
                      ) : (
                        <>
                          {allow_ecom_cart_and_checkout === "1" &&
                            <div className="bag-icon" onClick={handleAddToCart}>
                              <span className="cart-tooltip">Add to cart</span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="add-to-cart-icon"
                                width="18"
                                height="18"
                                viewBox="0 0 14 11.575"
                              >
                                <g
                                  id="Group_111"
                                  data-name="Group 111"
                                  transform="translate(-132.997 -1553.667)"
                                >
                                  <g
                                    id="Group_111-2"
                                    data-name="Group 111"
                                    transform="translate(133.997 1554.667)"
                                  >
                                    <path
                                      id="Subtraction_1"
                                      data-name="Subtraction 1"
                                      d="M9.539,8.826a.244.244,0,0,1-.127-.039H4.7a.227.227,0,0,1-.254,0c-.046,0-.1,0-.16,0H4.038a2.346,2.346,0,0,1-.319-.018,1.075,1.075,0,0,1-.883-.851,1.1,1.1,0,0,1,.516-1.173.123.123,0,0,0,.074-.165C2.988,4.55,2.561,2.548,2.2.858,2.186.788,2.158.766,2.085.766H1.162c-.19,0-.441,0-.693.006H.45A.435.435,0,0,1,0,.5V.306A.359.359,0,0,1,.4,0L1.463,0h.919a.431.431,0,0,1,.473.391c.042.188.083.379.122.565l0,.01.014.066.045.213c.046.212.046.212.253.212h8.277a.628.628,0,0,1,.191.022A.33.33,0,0,1,12,1.788v.17a6.265,6.265,0,0,0-.19.636c-.022.085-.044.168-.066.251-.276.993-.546,1.972-.845,3.058L10.6,7a.375.375,0,0,1-.425.328H3.895a.4.4,0,0,0-.118.014.365.365,0,0,0-.26.384.361.361,0,0,0,.334.33l.065,0h6.293a.49.49,0,0,1,.1.007.364.364,0,0,1,.287.386.357.357,0,0,1-.341.334c-.134,0-.269,0-.4,0H9.666A.243.243,0,0,1,9.539,8.826ZM6.859,6.6l3.008,0a.122.122,0,0,0,.142-.109c.2-.723.394-1.436.612-2.227l.224-.812c.112-.407.223-.813.336-1.224H3.218c0,.006,0,.01,0,.014a.077.077,0,0,0,0,.017l.147.683.056.261c.232,1.083.472,2.2.706,3.3.018.086.06.093.128.093Z"
                                      transform="translate(0 0)"
                                      fill="#fff"
                                      stroke="rgba(0,0,0,0)"
                                      stroke-miterlimit="10"
                                      stroke-width="2"
                                    ></path>
                                    <path
                                      id="Path_63"
                                      data-name="Path 63"
                                      d="M369.843,385.073h.215a.93.93,0,0,1,.612.366.858.858,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C369.807,385.085,369.828,385.09,369.843,385.073Z"
                                      transform="translate(-365.426 -376.279)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_64"
                                      data-name="Path 64"
                                      d="M581.767,385.073h.215a.93.93,0,0,1,.611.366.857.857,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C581.731,385.085,581.752,385.09,581.767,385.073Z"
                                      transform="translate(-571.964 -376.279)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_66"
                                      data-name="Path 66"
                                      d="M399.355,415.569a.3.3,0,1,1,.3-.294A.3.3,0,0,1,399.355,415.569Z"
                                      transform="translate(-394.799 -405.584)"
                                      fill="#fff"
                                    ></path>
                                    <path
                                      id="Path_67"
                                      data-name="Path 67"
                                      d="M611.574,415.273a.3.3,0,1,1-.3-.3A.3.3,0,0,1,611.574,415.273Z"
                                      transform="translate(-601.704 -405.586)"
                                      fill="#fff"
                                    ></path>
                                  </g>
                                </g>
                              </svg>
                            </div>
                          }
                        </>
                      )}
                    </>
                  )}
                </div>
                <div>
                  {+product.has_coupon > 0 && product.digital_coupon_details && (
                    <div className="deals-offer-text">
                      <p>{product.digital_coupon_details}</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
    </>
  );
};
export default ProductCardTheme2;
