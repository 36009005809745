import React, { useEffect, useRef, useState } from 'react';
import { decrypted } from 'config/crypto';
import { useDispatch, useSelector } from 'react-redux';
import { getLocalStorageValue } from 'config/helper';
import { getSavePlaceOrder, savePaymentTransactionId, savePaymentTransactionType } from 'redux/actions';
import { useNavigate } from 'react-router-dom';
import * as axiosLibrary from "axios";
import axios from "config/axios";

const ECryptPaymentGateway = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState(null);
    const { storeDetails, currentStoreID } = useSelector(({ store }) => store);
    const { onClose, paymentRequestBody, checkoutType } = props;
    const memberNumber = getLocalStorageValue("member-number");
    const userToken = getLocalStorageValue("user-token");
    const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
    const { authorized_amount, member_number, user_token, total_amount } = paymentRequestBody;
    const paymentAmount = checkoutType ? +total_amount : +authorized_amount;
    const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
    const clientId = getLocalStorageValue("RSAclient-id");
    const [paymentLoading, setPaymentLoading] = useState(false);
    const [paymentError, setPaymentError] = useState(false);
    const {
        savePlaceOrderError,
    } = useSelector(({ checkout }) => checkout);

    useEffect(() => {
        const ecryptConfigScript = document.createElement('script');
        const publicKey = decrypted(storeDetails?.repuk);
        ecryptConfigScript.type = 'text/javascript';
        ecryptConfigScript.innerHTML = `
            var ecryptConfig = {
            appearance: {
            bodyBackground: "#fff",
            formBackground: "#fff",
            inputColor: "#000",
            inputBorderColor: "#1A1A1A",
            inputBackground: "#fff",
            errorColor: "#d90429",
            successColor: "#2BBD53",
            focusColor: "#000",
            hidealerts: false,
            googleFontFamily: "'Roboto', sans-serif",
            },
            theme: "long-form-border-light",
            publicKey: "${publicKey}",
            
            };
        `;
        document.body.appendChild(ecryptConfigScript);

        const ecryptJSScript = document.createElement('script');
        ecryptJSScript.src = 'https://iframe.ecrypt.com/js/ecrypt_iframe.js';
        document.body.appendChild(ecryptJSScript);

        window.addEventListener('message', (event) => {
            if (event.origin !== window.location.origin) {
                var msg = event.data;
                if (typeof msg === 'string' && msg.includes('token')) {
                    const parsedMsg = JSON.parse(msg);
                    const parseResponse = JSON.parse(parsedMsg);
                    setToken(parseResponse.token);
                }
            }
        });
    }, []);

    const handleSaveEcryptTransaction = (response, token, last_new_order_id) => {
        const { order_id } = response;
        setPaymentLoading(true);
        dispatch(savePaymentTransactionId(token));
        dispatch(savePaymentTransactionType("ecrypt"));
        const body = {
            last_new_order_id: last_new_order_id,
            authorized_amount: +paymentAmount || 0,
            ecrypt_token: token,
            user_token,
            AppName: "shop",
            grand_plus_discount_total: +paymentAmount || 0,
            DeviceType: "web",
            ClientStoreId: +storeId || 1,
            Version: 1,
            RSAClientId: clientId,
            order_number: order_id,
            payment_type: "ecrypt",
            member_number,
        };
        axios
            .post("/AogSaveEcryptTransaction", body)
            .then((res) => {
                setPaymentLoading(false);
                if (res.data.status === "success") {
                    onClose();
                    navigate("/order-confirmation");
                    setPaymentError(false);
                }
                else {
                    setPaymentError(true);
                }
            })
            .catch((error) => {
                onClose();
                setPaymentLoading(false);
                setPaymentError(true);
            });
    };

    const closeModal = () => {
        if (typeof onClose === "function") {
            onClose();
        }
    };
    const saveEcryptTransaction = () => {
        setPaymentLoading(true);
        dispatch(getSavePlaceOrder(paymentRequestBody)).then((res) => {
            handleSaveEcryptTransaction(
                res.data.data,
                token,
                res.data.last_new_order_id
            );
        });
    };
    return (
        <>
            <div className="modal-backdrop payment-model-wrap">
                <div className="modal-lg place-order-popup store-payment-modal">
                    <span className="help-text">Please enter credit card details below</span>
                    <div id="ecrypt-iframe-wrapper">

                    </div>
                    <div
                        className="close-btn"
                        onClick={onClose}
                        title="Close"
                    >
                        x
                    </div>
                    {paymentError && <div className="error-msg" style={{ color: 'red' }}>Something went wrong. Please try again.</div>}
                    <div className="pay-btn-wrap">
                        <button
                            disabled={!token}
                            onClick={() => saveEcryptTransaction()}
                            className={`${(!token || paymentLoading) ? 'pay-btn-disabled' : 'pay-btn '}`}
                        >
                            {paymentLoading ? "Processing" : `Pay $${paymentAmount}`}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};
export default ECryptPaymentGateway;
