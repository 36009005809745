import {
  FETCH_CATERING_DEPARTMENT_TREE,
  FETCH_CATERING_DEPARTMENT_TREE_SUCCESS,
  FETCH_CATERING_DEPARTMENT_TREE_FAILURE,
  FETCH_CATERING_PRODUCT_LIST,
  FETCH_CATERING_PRODUCT_LIST_SUCCESS,
  FETCH_CATERING_PRODUCT_LIST_FAILURE,
  UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION,
  RESET_SELECTED_CUSTOM_OPTIONS,
  UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION,
  RESET_SELECTED_CART_CUSTOM_OPTIONS,
} from "redux/constants/actionTypes";

let initialState = {
  cateringDepartmentTree: [],
  cateringDepartmentTreeLoading: true,
  cateringDepartmentTreeError: "",
  cateringList: [],
  cateringListLoading: true,
  cateringListError: "",
  cateringListTotalCount: 0,
  cateringListTotalPages: 0,
  cateringListTotalRecords: 0,
  categorySection: {},
  selectedCateringAndMealOption: {},
  selectedCateringAndMealCartOption: [],
};

const cateringAndMealReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CATERING_DEPARTMENT_TREE:
      return {
        ...state,
        cateringDepartmentTreeLoading: true,
      };
    case FETCH_CATERING_DEPARTMENT_TREE_SUCCESS:
      return {
        ...state,
        cateringDepartmentTreeLoading: false,
        cateringDepartmentTree: action.payload,
      };
    case FETCH_CATERING_DEPARTMENT_TREE_FAILURE:
      return {
        ...state,
        cateringDepartmentTreeError: action.payload.error,
      };

    case FETCH_CATERING_PRODUCT_LIST:
      return {
        ...state,
        cateringList: [],
        cateringListLoading: true,
      };
    case FETCH_CATERING_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        cateringListLoading: false,
        cateringList: action.payload.data,
        cateringListTotalCount: action.payload.TotalRecords || 0,
        cateringListTotalPages: action.payload.NoOfPages || 0,
        cateringListTotalRecords: action.payload.TotalRecords || 0,
        categorySection: action.payload.categories_section,
      };
    case FETCH_CATERING_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        cateringListError: action.payload.error,
      };
    case RESET_SELECTED_CUSTOM_OPTIONS:
      return {
        ...state,
        selectedCateringAndMealOption: {},
      };
    case UPDATE_CATERING_AND_MEAL_CUSTOM_OPTION:
      return {
        ...state,
        selectedCateringAndMealOption: {
          ...state.selectedCateringAndMealOption,
          ...action.payload,
        },
      };

    case RESET_SELECTED_CART_CUSTOM_OPTIONS:
      return {
        ...state,
        selectedCateringAndMealCartOption: [],
      };
    case UPDATE_CATERING_AND_MEAL_CART_CUSTOM_OPTION:
      let updatedCartOptionData = state.selectedCateringAndMealCartOption;
      if (action.replace) {
        const selectedOptions = {
          ...updatedCartOptionData[action.productIndex],
          ...action.payload,
        };
        updatedCartOptionData[action.productIndex] = selectedOptions;
      } else {
        updatedCartOptionData[action.productIndex] = action.payload;
      }
      return {
        ...state,
        selectedCateringAndMealCartOption: updatedCartOptionData,
      };
    default:
      return state;
  }
};

export default cateringAndMealReducer;
