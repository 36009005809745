import React, { useEffect, useState } from "react";
import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import { useDispatch, useSelector } from "react-redux";
import { FormComponent, FormContainer } from "react-authorize-net";
import {
  getSavePlaceOrder,
  getSavePlaceOrderCateringAndMeals,
  savePaymentTransactionId,
  savePaymentTransactionType,
} from "redux/actions";
import * as axiosLibrary from "axios";
import axios from "config/axios";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { getLocalStorageValue } from "config/helper";
import { getCartList } from "redux/actions/cartWishlist";
import { decrypted } from 'config/crypto';

const PaymentModal = (props) => {
  const refDelivery = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isShowPaymentPopup, onClose, paymentRequestBody, checkoutType } =
    props;

  const { currentStoreID } = useSelector(({ store }) => store);
  const { cateringAndMealKitProductCartList } = useSelector(
    ({ cartWishlist }) => cartWishlist
  );

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");

  const [isshowError, setShowError] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const { storeDetails } = useSelector(({ store }) => store);
  const {
    savePlaceOrder,
    // savePlaceOrderCateringAndMeal
  } = useSelector(({ checkout }) => checkout);

  const [message, setMessage] = useState(null);
  const [SaveTransactionCallErrormessage, setSaveTransactionCallErrorMessage] =
    useState(null);
  const [SaveTransactionCallRespStatus, setSaveTrasactionCallRespStatus] =
    useState(null);
  const memberNumber = getLocalStorageValue("member-number");
  const userToken = getLocalStorageValue("user-token");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: "shop",
      member_number: memberNumber,
      user_token: userToken,
      Version: "1",
      DeviceType: "web",
    };
    isUserLoggedIn &&
      SaveTransactionCallRespStatus === "error" &&
      dispatch(getCartList(body));
  }, [
    isUserLoggedIn,
    storeId,
    SaveTransactionCallRespStatus,
    clientId,
    memberNumber,
    userToken,
    dispatch,
  ]);

  const {
    order_number,
    customer_email,
    customer_first_name,
    customer_last_name,
    billing_address_1,
    billing_address_2,
    billing_city,
    billing_state,
    billing_zipcode,
  } = savePlaceOrder;

  const {
    rck,
    authorize_mode,
    rmli,
    rmtk,
    allow_mealkit_authorizenet_with_authorize_or_capture,
    allow_catering_authorizenet_with_authorize_or_capture,
    company_name,
    ClientStoreName,
    rspk,
    payment_gateway,
  } = storeDetails;
  const { authorized_amount, member_number, user_token, total_amount } =
    paymentRequestBody;

  const paymentAmount = checkoutType ? +total_amount : +authorized_amount;

  useOnClickOutside(refDelivery, () => onClose());
  //stripe
  const stripePromise = loadStripe(decrypted(rspk));

  const requestUrl =
    authorize_mode && authorize_mode === "sandbox"
      ? "https://apitest.authorize.net/xml/v1/request.api"
      : "https://api.authorize.net/xml/v1/request.api";

  const { is_mealkit = "0" } = cateringAndMealKitProductCartList[0] || {};

  const allowAuthorizeNetOrCapture =
    is_mealkit === "1"
      ? allow_mealkit_authorizenet_with_authorize_or_capture
      : allow_catering_authorizenet_with_authorize_or_capture;

  // grocery
  const getSaveTransaction = (responce, request, last_new_order_id) => {
    const {
      transactionResponse: { transId, responseCode, messages, errors },
    } = responce;

    dispatch(savePaymentTransactionId(transId));
    dispatch(savePaymentTransactionType("authorize.net"));

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      member_number,
      payment_message: messages
        ? messages[0].description
        : errors
          ? errors[0].errorText
          : responce?.messages?.message[0].text,
      last_new_order_id: last_new_order_id,
      payment_status: messages ? "1" : "3",
      is_payment_response_null: responce === null ? 1 : 0,
      transaction_id: transId || "0",
      trr_type: "new",
      transaction_authorize_request: JSON.stringify(request),
      transaction_authorize_response: JSON.stringify(responce),
      user_token,
      AppName: "shop",
      DeviceType: "web",
      Version: 1,
    };
    return axios
      .post("/AogSaveTransaction", body)
      .then((res) => {
        setSaveTransactionCallErrorMessage(res?.data?.message);
        setSaveTrasactionCallRespStatus(res?.data?.status);
        return res.data;
      })
      .catch((error) => {
        throw error;
      });
  };
  // grocery
  const getAuthoriseResponce = async (body, lastNewOrderId) => {
    setPaymentLoading(true);

    try {
      const response = await axios.post(requestUrl, body);

      // Define default error message
      const errorMessage = "An error occurred during processing with Merchant Service Provider. We're sorry for the inconvenience. Please try again, and if this occurs again, contact store support";

      // Determine if there's an error
      const isError = response.data?.messages?.resultCode === "Error" ||
        response.data?.transactionResponse?.responseCode !== "1" &&
        response.data.transactionResponse?.errors?.length > 0;

      // Set error message
      setMessage(isError ? errorMessage : "");

      // Save transaction data
      const transactionData = await getSaveTransaction(response.data, body, lastNewOrderId);

      // Check transaction status and navigate if no error
      if (transactionData?.status !== "error" && response.data?.messages?.resultCode !== "Error") {
        navigate("/order-confirmation");
      }
    } catch (error) {
      setPaymentLoading(false);
    }
    finally {
      setPaymentLoading(false);
    }
  };


  // grocery
  const handleAuthoriseRequest = (response, last_new_order_id, orderNumber) => {
    const {
      opaqueData: { dataDescriptor = "", dataValue = "" },
    } = response;
    const body = {
      createTransactionRequest: {
        merchantAuthentication: {
          name: decrypted(rmli),
          transactionKey: decrypted(rmtk),
        },
        transactionRequest: {
          transactionType: "authOnlyTransaction",
          amount: paymentAmount,
          payment: {
            opaqueData: {
              dataDescriptor: dataDescriptor,
              dataValue: dataValue,
            },
          },
          order: {
            invoiceNumber: orderNumber,
            description: `${company_name} ${ClientStoreName} Order Number: ${orderNumber}`,
          },
          customer: { id: member_number, email: customer_email },
          billTo: {
            firstName: customer_first_name,
            lastName: customer_last_name,
            address: billing_address_1 + billing_address_2 || "",
            city: billing_city,
            state: billing_state,
            zip: billing_zipcode,
          },
        },
      },
    };
    getAuthoriseResponce(body, last_new_order_id);
  };
  // catering and meal kit
  const getSaveCateringAndMealkitTransaction = (responce, request, apiRes) => {
    const {
      data: { customer_id = "", order_number = "" },
      last_catering_or_mealkit_order_id = "",
    } = apiRes;
    const {
      transactionResponse: { transId, responseCode, messages, authCode },
    } = responce;

    dispatch(savePaymentTransactionId(transId));
    dispatch(savePaymentTransactionType("authorize.net"));
    const body = {
      ClientStoreId: +storeId || 1,
      DeviceType: "web",
      grand_total: paymentAmount,
      is_payment_response_null: responce === null ? 1 : 0,
      last_catering_or_mealkit_order_id: last_catering_or_mealkit_order_id,
      member_number,
      user_token,
      AppName: "shop",
      payment_message: messages[0].description,
      payment_status: responseCode,
      RSAClientId: clientId,
      transaction_authorize_full_response: JSON.stringify(responce),
      transaction_authorize_request: {
        additional_authorization_percentage_on_total_order: "0.00",
        authorized_amount: paymentAmount,
        bill_address_1: "",
        bill_city: "",
        bill_company: "",
        bill_country: "",
        bill_firstname: "",
        bill_lastname: "",
        bill_state: "",
        bill_zipcode: "",
        customer_id,
        customer_name: "",
        DeviceType: "web",
        grand_total: paymentAmount,
        last_catering_or_mealkit_order_id: last_catering_or_mealkit_order_id,
        member_number,
        order_description: messages[0].description,
        order_number,
        set_email: "",
        set_id: "",
        set_user_field_name: "",
        set_user_field_name_value: "",
        store_id: "",
        trr_type: "new",
      },
      transaction_authorize_response: {
        auth_code: authCode,
        DeviceType: "web",
        error_message: messages[0].description,
        level: "1",
        transaction_id: transId,
        transaction_response_code: responseCode,
        transaction_response_message: messages[0].description,
      },
      transaction_id: transId,
      Version: 1,
    };

    axios
      .post("/SaveCateingAndMealkitAuthorizeNetTransaction", body)
      .then((res) => { })
      .catch((error) => { });
  };
  // catering and meal kit
  const getCateringAndMealkitAuthoriseResponce = (body, apiRes) => {
    axiosLibrary
      .post(requestUrl, body)
      .then((res) => {
        getSaveCateringAndMealkitTransaction(res.data, body, apiRes);
        setPaymentLoading(false);
        navigate("/order-confirmation");
      })
      .catch((error) => {
        setPaymentLoading(false);
      });
  };

  // catering and meal kit
  const handleCateringAndMealkitAuthoriseRequest = (response, apiRes) => {
    const {
      opaqueData: { dataDescriptor = "", dataValue = "" },
    } = response;

    const body = {
      createTransactionRequest: {
        merchantAuthentication: {
          name: decrypted(rmli),
          transactionKey: decrypted(rmtk),
        },
        transactionRequest: {
          transactionType:
            allowAuthorizeNetOrCapture === "0"
              ? "authOnlyTransaction"
              : "authCaptureTransaction",
          amount: paymentAmount,
          payment: {
            opaqueData: {
              dataDescriptor: dataDescriptor,
              dataValue: dataValue,
            },
          },
          order: {
            invoiceNumber: order_number,
            description: `${company_name} ${ClientStoreName} Order Number: ${order_number}`,
          },
          customer: { id: member_number, email: customer_email },
          billTo: {
            firstName: customer_first_name,
            lastName: customer_last_name,
            address: billing_address_1 + billing_address_2 || "",
            city: billing_city,
            state: billing_state,
            zip: billing_zipcode,
          },
        },
      },
    };
    getCateringAndMealkitAuthoriseResponce(body, apiRes);
  };

  const errorHandler = () => {
    setShowError(true);
    setPaymentLoading(false);
  };

  const successHandler = (response) => {
    setPaymentLoading(true);

    if (checkoutType === "catering-meal") {
      //catering and mealkit
      dispatch(getSavePlaceOrderCateringAndMeals(paymentRequestBody)).then(
        (res) => {
          if (res && res.data.status == 'success') {
            handleCateringAndMealkitAuthoriseRequest(response, res.data);
          } else {
            onClose();
          }
        }
      );
    } else {
      //grocery
      dispatch(getSavePlaceOrder(paymentRequestBody)).then((res) => {
        if (res.data.status == 'error') {
          setMessage(res.data.message);
          onClose();
        } else {
          handleAuthoriseRequest(response, res?.data?.last_new_order_id ?? "", res?.data?.data?.order_number ?? "");
        }
      });
    }
  };


  const StyledFormComponent = (props) => (
    <>
      <FormComponent {...props} className="cc-payment-form" />
      {(message || SaveTransactionCallErrormessage) && (
        <div
          style={{
            MarginTop: "20px",
            padding: "10px 20px",
            borderRadius: "5px",
            backgroundColor: "#4caf50",
            color: "black",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            textAlign: "center",
          }}
        >
          {message}
          <br />
          {SaveTransactionCallErrormessage && SaveTransactionCallErrormessage}
        </div>
      )}
    </>
  );

  //stripe grocery
  const handleSaveStripeTransaction = (response, token, last_new_order_id) => {
    const { order_id } = response;
    dispatch(savePaymentTransactionId(token));
    dispatch(savePaymentTransactionType("stripe"));
    const body = {
      last_new_order_id: last_new_order_id,
      authorized_amount: +paymentAmount || 0,
      stripeToken: token,
      user_token,
      AppName: "shop",
      grand_plus_discount_total: +paymentAmount || 0,
      DeviceType: "web",
      ClientStoreId: +storeId || 1,
      Version: 1,
      RSAClientId: clientId,
      order_number: order_id,
      payment_type: "stripe",
      member_number,
    };
    axios
      .post("/AogSaveStripeTransaction", body)
      .then((res) => {
        setPaymentLoading(false);
        navigate("/order-confirmation");
      })
      .catch((error) => { });
  };

  //stripe catering and mealkit
  const handleSaveStripeTransactionCateringAndMealKit = (
    response,
    token,
    last_new_order_id
  ) => {
    const { order_number } = response;
    dispatch(savePaymentTransactionId(token));
    dispatch(savePaymentTransactionType("stripe"));
    const body = {
      last_catering_or_mealkit_order_id: last_new_order_id,
      grand_total: +paymentAmount || 0,
      stripeToken: token,
      user_token,
      AppName: "shop",
      DeviceType: "web",
      ClientStoreId: +storeId || 1,
      Version: 1,
      RSAClientId: clientId,
      order_number: order_number,
      member_number,
    };
    axios
      .post("/SaveCateingAndMealkitStripeTransaction", body)
      .then((res) => {
        setPaymentLoading(false);
        navigate("/order-confirmation");
      })
      .catch((error) => { });
  };

  //stripe
  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      event.preventDefault();

      if (elements == null) {
        return;
      }
      const cardElement = elements.getElement("card");
      const {
        error,
        token: { id },
      } = await stripe.createToken(cardElement);

      if (error) {
        setShowError(true);
        return;
      }
      setPaymentLoading(true);

      if (checkoutType === "catering-meal") {
        dispatch(getSavePlaceOrderCateringAndMeals(paymentRequestBody)).then(
          (res) => {
            if (res.data.status == 'error') {
              setMessage(res.data.message);
              onClose();
            } else {
              handleSaveStripeTransactionCateringAndMealKit(
                res.data.data,
                id,
                res.data.last_catering_or_mealkit_order_id
              );
            }
          }
        );
      } else {
        dispatch(getSavePlaceOrder(paymentRequestBody)).then((res) => {
          handleSaveStripeTransaction(
            res.data.data,
            id,
            res.data.last_new_order_id
          );
        });
      }
    };

    return (
      <form onSubmit={handleSubmit} className="stripe-payment-form">
        <CardElement
          options={{
            style: {
              base: {
                fontSize: "16px",
                color: "#424770",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        <div className="stripe-button-wrapper">
          <button
            type="submit"
            disabled={!stripe || !elements}
            className="stripe-button"
          >
            Pay${+paymentAmount}
          </button>
        </div>
      </form>
    );
  };

  const paymentGateway = (mode) => {
    switch (mode) {
      case "stripe": {
        return (
          <Elements stripe={stripePromise}>
            <CheckoutForm />
          </Elements>
        );
      }
      case "authorize.net": {
        return (
          <FormContainer
            environment={authorize_mode}
            onError={errorHandler}
            onSuccess={successHandler}
            amount={paymentAmount ? +paymentAmount : 0}
            component={(props) => (
              <StyledFormComponent {...props} message={message} />
            )}
            clientKey={decrypted(rck)}
            apiLoginId={decrypted(rmli)}
          />
        );
      }

      default: {
        return null;
      }
    }
  };

  return (
    <>
      {isShowPaymentPopup && (
        <>
          <div className="modal-backdrop">
            <div className="modal-lg store-selector-modal" ref={refDelivery}>
              <div className="payment-modal-body">
                {isPaymentLoading ? (
                  <div className="processing-card">
                    <div className="client-logo">
                      <img
                        src={headerSectionData.rsa_client_logo}
                        alt={headerSectionData.image_alt_text}
                      />
                    </div>
                    <div className="payment-process-message">
                      <h2>Processing...</h2>
                      <p>
                        We are processing your payment. Please do not close or
                        refresh the screen...
                      </p>
                    </div>
                  </div>
                ) : !isshowError && !isPaymentLoading ? (
                  <div className="form-wrapper">
                    <span className="help-text">
                      Please enter credit card details below
                    </span>
                    {paymentGateway(payment_gateway)}
                  </div>
                ) : isshowError ? (
                  <div className="payment-error-message">
                    <div>
                      <h2>Transaction failed, please try again!</h2>
                      <div className="btn-center">
                        <button onClick={() => setShowError(false)}>
                          Try again
                        </button>
                        <button onClick={onClose}>Go back</button>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PaymentModal;
