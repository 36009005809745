import React from "react";
import "./spend-reward.scss";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const SpendReward = ({ lmRewardData }) => {
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const BuyQtyAmount = lmRewardData?.buy_qty_amount;
  const RoundedPurchasedAmount =
    lmRewardData?.RoundedPurchasedAmount !== ""
      ? lmRewardData?.RoundedPurchasedAmount
      : lmRewardData?.purchased_amount;
  return (
    <div className="reward-main-wrap">
      <div className="reward-box">
        <div className="reward-img">
          <img src={lmRewardData?.image_url} alt="product_image" />
        </div>
        <div className="reward-content-wrapper">
          {/* <div className="reward-subtitle"> {lmRewardData?.RewardTitle}</div> */}
          <div className="reward-title-btn">
            <div className="reward-title">{lmRewardData?.title}</div>
            <div className="reward-btn">
              <Link
                to="/rewardsdetails"
                state={{
                  lmRewardId: lmRewardData?.lm_reward_id,
                  isPointBased: lmRewardData?.is_points_based,
                  lmRewardData: lmRewardData,
                }}
              >
                view details
              </Link>
            </div>
          </div>
          <div className="reward-range-bar">
            <div className="progress-ba">
              {RoundedPurchasedAmount >= BuyQtyAmount ? (
                <div>
                  <h1 style={{ color: "red" }}>
                    Congratulations! You earned a reward and it is on the way.
                  </h1>
                  <p>
                    Please allow 15-30 min before you see teh reward in your
                    list. It applies to your next purchase.
                  </p>
                </div>
              ) : (
                <div
                  style={{
                    position: "relative",
                  }}
                >
                  <progress
                    value={Math.max(RoundedPurchasedAmount, 20)}
                    max={BuyQtyAmount}
                    style={{ height: "100%", width: "100%" }}
                    className="spend-progress-bar"
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: `${Math.max(
                        100 -
                          (((BuyQtyAmount - RoundedPurchasedAmount) /
                            BuyQtyAmount) *
                            100) /
                            2,
                        10
                      )}%`,
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#ffffff", // Change color according to your preference
                      zIndex: "1",
                    }}
                  >
                    {BuyQtyAmount - RoundedPurchasedAmount}
                  </span>
                  <span
                    style={{
                      position: "absolute",
                      left: `${Math.max(
                        ((RoundedPurchasedAmount / BuyQtyAmount) * 100) / 2,
                        3
                      )}%`,
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#ffffff", // Change color according to your preference
                      zIndex: "1",
                    }}
                  >
                    {RoundedPurchasedAmount}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendReward;
