//import Dropdown from "components/Common/Dropdown";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./employment-form.scss";
import { useForm } from "react-hook-form";
import { addEmployee } from "redux/actions";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { getLocalStorageValue } from "config/helper";
import ReCAPTCHA from "react-google-recaptcha";

const EmploymentForm = ({ jobList, setCallFormSubmit, callFormSubmit }) => {
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const storeList = useSelector(({ store }) => store.storeListData);
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const storeDetail = storeList.find(
    (store) => store.ClientStoreId === storeId + ""
  );
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const recaptchaRef = React.createRef();

  const [captchaError, setCaptchaError] = useState("");
  const [recaptchaValue, setRecaptchaValue] = useState("");

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );

  const { your_recaptcha_site_key } = headerSectionData;

  const optionList = [
    { title: "Yes", value: "Yes" },
    { title: "No", value: "No" },
  ];
  const { employmentStatus, employmentMessage } = useSelector(
    ({ employment }) => employment
  );

  const [selectedCurrentHighSchool, setSelectedCurrentHighSchool] = useState();

  const [selectedCurrentAge, setSelectedCurrentAge] = useState();

  const [selectedCredits, setSelectedCredits] = useState();

  const [selectedDegree, setSelectedDegree] = useState();

  const [selectedEmployment, setSelectedEmployment] = useState();

  const [selectedAgeOption, setSelectedAgeOption] = useState();

  const [selectedInfo, setSelectdeinfo] = useState();

  const defaultValues = {
    selectStore: storeDetail?.ClientStoreName ?? "",
    jobId: jobId ? jobId : "",
  };
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues,
  });

  useEffect(() => {
    setValue("jobId", jobId ? jobId : "");
  }, [jobId]); //eslint-disable-line

  const [selectedStoreLocation, setSelectedStoreLocation] = useState();
  const [checkbox, setCheckbox] = useState(false);
  const [selectJobid, setSelectJobid] = useState(jobId ? jobId : "");

  const handleSelectCurrentHighSchool = (_, optionName) => {
    setSelectedCurrentHighSchool(optionName);
  };

  const handleSelectedCurrentAge = (_, optionName) => {
    setSelectedCurrentAge(optionName);
  };

  const handleSelectCredits = (_, optionName) => {
    setSelectedCredits(optionName);
  };
  const handleSelectDegree = (_, optionName) => {
    setSelectedDegree(optionName);
  };
  const handleSelectEmployment = (_, optionName) => {
    setSelectedEmployment(optionName);
  };
  const handleSelectAgeOption = (_, optionName) => {
    setSelectedAgeOption(optionName);
  };
  const handleSelectedinfo = (_, optionName) => {
    setSelectdeinfo(optionName);
  };

  const handleJobid = (_, optionName) => {
    setSelectJobid(optionName);
  };
  const handleSelectStoreLocation = (_, optionName) => {
    setSelectedStoreLocation(optionName);
  };

  const onChangeoption = () => {
    setCheckbox(true);
  };

  const getAgeList = () => {
    let ageOptionList = [];
    for (let i = 16; i <= 100; i++) {
      ageOptionList.push(<option value={i}>{i}</option>);
    }
    return ageOptionList;
  };

  const handleSubmitemploymentForm = (data, e) => {
    e.preventDefault();
    const {
      Adress,
      City,
      Country,
      State,
      zipCode,
      city2,
      company_name1,
      country2,
      date2,
      days_available_for_work,
      do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us,
      emailId,
      end_date,
      hours_available_for_work,
      how_long_have_you_lived_at_this_address,
      how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above,
      are_you_currently_in_high_school,
      college_or_university_credits,
      did_you_earn_a_degree,
      name,
      name1,
      phoneNo,
      phone_number1,
      postal,
      salary,
      selectStore,
      start_date,
      state2,
      the_job_duties,
      street_address1,
      additional_notes,
      reason_for_leaving,
      are_you_eligible_for_employment_in_the_united_states,
      referred_by,
      wage_expected,
      the_information,
      jobId,
      current_age,
      are_you_over_the_age_of_18,
    } = data;

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      name: name,
      phone: phoneNo,
      email: emailId,
      street_address: Adress,
      city: City,
      state: State,
      zip_code: zipCode,
      country: Country,
      how_long_have_you_lived_at_this_address:
        how_long_have_you_lived_at_this_address,
      wage_expected: wage_expected,
      date_you_can_start: start_date,
      do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us:
        do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us,
      are_you_at_least_18_years_of_age_if_not_how_old:
        are_you_currently_in_high_school,
      are_you_currently_in_high_school: are_you_currently_in_high_school,
      college_or_university_credits: college_or_university_credits,
      did_you_earn_a_degree: did_you_earn_a_degree,
      company_name1: company_name1,
      phone_number1: phone_number1,
      street_address1: street_address1,
      city2: city2,
      state2: state2,
      postal: postal,
      country2: country2,
      start_date: start_date,
      end_date: end_date,
      salary: salary || "",
      how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above:
        how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above,
      the_job_duties: the_job_duties,
      days_available_for_work: days_available_for_work,
      hours_available_for_work: hours_available_for_work,
      the_information: the_information,
      name1: name1,
      store_location: selectStore,
      date2: date2,
      referred_by: referred_by,
      reason_for_leaving: reason_for_leaving ? reason_for_leaving : "",
      additional_notes: additional_notes,
      are_you_eligible_for_employment_in_the_united_states:
        are_you_eligible_for_employment_in_the_united_states,
      job_id: jobId,
      current_age: current_age,
      are_you_over_the_age_of_18: are_you_over_the_age_of_18,
    };

    if (recaptchaValue) {
      setCallFormSubmit(true);
      dispatch(addEmployee(body)).then((res) => {
        if (res.data.status === "success") {
          e.target.reset();
          setCallFormSubmit(false);
        }
      });
    } else {
      setCaptchaError("The captcha field is required");
    }
  };

  const onChangeReCaptcha = (value) => {
    setCaptchaError("");
    setRecaptchaValue(value);
  };

  const AllowedSalaryFieldClientId = ["159", "282"];

  const isAllowedSalaryField = AllowedSalaryFieldClientId.filter(client => client === clientId).length;

  return (
    <>
      {/* {
      jobList.length >0 &&( */}

      <div>
        <div className="container">
          <div className="employment-form-section">
            <form
              name="employemnt"
              onSubmit={handleSubmit(handleSubmitemploymentForm)}
            >
              <div className="four-col-grid">
                <div className="form-control">
                  <label>
                    Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    ref={register({
                      required: true,
                      pattern: /^[A-Za-z]/,
                    })}
                  />
                  {errors.name && errors.name.type === "required" && (
                    <p>Please enter your Name</p>
                  )}
                  {errors.name && errors.name.type === "pattern" && (
                    <p>Please enter valid Name</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Phone<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="phoneNo"
                    id="phoneNo"
                    maxLength={10}
                    ref={register({
                      required: true,
                      pattern: /^[0-9]{10}$/,
                    })}
                  />

                  {errors.phoneNo && errors.phoneNo.type === "required" && (
                    <p>Please enter your Phone No</p>
                  )}
                  {errors.phoneNo && errors.phoneNo.type === "pattern" && (
                    <p>Please enter valid Phone No</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="emailId"
                    id="emailId"
                    ref={register({
                      required: true,
                      pattern:
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                    })}
                  />
                  {errors.emailId && errors.emailId.type === "required" && (
                    <p>Please enter Email Id</p>
                  )}
                  {errors.emailId && errors.emailId.type === "pattern" && (
                    <p>Please enter Email Id</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Street Address<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="Adress"
                    id="Adress"
                    ref={register({ required: true })}
                  />
                  {errors.Adress && errors.Adress.type === "required" && (
                    <p>Please enter your Address</p>
                  )}
                </div>
              </div>

              <div className="four-col-grid">
                <div className="form-control">
                  <label>
                    City<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="City"
                    id="City"
                    ref={register({ required: true })}
                  />
                  {errors.City && errors.City.type === "required" && (
                    <p>Please enter City Name</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    State / Province / Region<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="State"
                    id="State"
                    ref={register({ required: true })}
                  />
                  {errors.State && errors.State.type === "required" && (
                    <p>Please enter your State</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Zip Code<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="zipCode"
                    id="zipCode"
                    maxLength={5}
                    ref={register({
                      required: true,
                      pattern: /^[0-9\b]{5}$/,
                    })}
                  />
                  {errors.zipCode && errors.zipCode.type === "required" && (
                    <p>Please enter Zipcode</p>
                  )}
                  {errors.zipCode && errors.zipCode.type === "pattern" && (
                    <p>Please enter valid Zipcode</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Country<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="Country"
                    id="Country"
                    ref={register({ required: true })}
                  />
                  {errors.Country && errors.Country.type === "required" && (
                    <p>Please enter Country Name</p>
                  )}
                  {errors.Country && errors.Country.type === "pattern" && (
                    <p>Please enter valid Last Name</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    How long have you lived at this address?<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="how_long_have_you_lived_at_this_address"
                    id="how_long_have_you_lived_at_this_address"
                    ref={register({ required: true })}
                  />
                  {errors.how_long_have_you_lived_at_this_address &&
                    errors.how_long_have_you_lived_at_this_address.type ===
                    "required" && (
                      <p>
                        Please enter How long have you lived at this address
                      </p>
                    )}
                </div>

                <div className="form-control">
                  <label>Referred By</label>
                  <input type="text" name="referred_by" id="referred_by" />
                </div>
                <div className="form-control">
                  <label>
                    Wage expected<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="wage_expected"
                    id="wage_expected"
                    ref={register({ required: true })}
                  />
                  {errors.wage_expected &&
                    errors.wage_expected.type === "required" && (
                      <p>Please enter Wage expected </p>
                    )}
                </div>
                <div className="form-control">
                  <label>
                    Date you can start<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="start_date"
                    id="start_date"
                    ref={register({ required: true })}
                  />
                  {errors.start_date &&
                    errors.start_date.type === "required" && (
                      <p>Please enter start date</p>
                    )}
                </div>
              </div>

              <div className="one-col-grid">
                <div className="form-control">
                  <label>
                    Do you now or have you ever had a relative (includes
                    in-laws) that worked for {headerSectionData.client_name} ?
                    (Yes or No)
                    <br />
                    If yes, please list their name(s) and location(s)
                    <span>*</span>
                  </label>
                  <input
                    type="text"
                    name="do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us"
                    id="do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us"
                    ref={register({ required: true })}
                  />
                  {errors.do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us &&
                    errors
                      .do_you_now_or_have_you_ever_had_a_relative_includes_in_laws_that_worked_for_us
                      .type === "required" && <p>Please List Names</p>}
                </div>
              </div>
              <div className="three-col-grid">
                <div className="form-control">
                  <label>
                    Are you currently in High School (Yes or No)<span>*</span>
                  </label>

                  <select
                    value={selectedCurrentHighSchool}
                    name="are_you_currently_in_high_school"
                    onChange={handleSelectCurrentHighSchool}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {optionList.length > 0 &&
                      optionList.map((option) => {
                        return (
                          <option value={option.value}>{option.title}</option>
                        );
                      })}
                  </select>
                  {errors.are_you_currently_in_high_school &&
                    errors.are_you_currently_in_high_school.type ===
                    "required" && (
                      <p>Please Select are you currently in High School?</p>
                    )}
                </div>
                <div className="form-control">
                  <label>
                    College or University Credits (Yes or No)<span>*</span>
                  </label>

                  <select
                    value={selectedCredits}
                    name="college_or_university_credits"
                    onChange={handleSelectCredits}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {optionList.length > 0 &&
                      optionList.map((option) => {
                        return (
                          <option value={option.value}>{option.title}</option>
                        );
                      })}
                  </select>
                  {errors.college_or_university_credits &&
                    errors.college_or_university_credits.type ===
                    "required" && (
                      <p>Please Select college/University credits</p>
                    )}
                </div>
                <div className="form-control">
                  <label>
                    Did you earn a degree(s)? (Yes or No)<span>*</span>
                  </label>

                  <select
                    value={selectedDegree}
                    name="did_you_earn_a_degree"
                    onChange={handleSelectDegree}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {optionList.length > 0 &&
                      optionList.map((option) => {
                        return (
                          <option value={option.value}>{option.title}</option>
                        );
                      })}
                  </select>
                  {errors.did_you_earn_a_degree &&
                    errors.did_you_earn_a_degree.type === "required" && (
                      <p>Please Select did you earn a degree</p>
                    )}
                </div>
              </div>
              <div className="tow-col-grid">
                <div className="tow-col-grid-items">
                  <div className="form-control">
                    <label>
                      Company Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="company_name1"
                      id="company_name1"
                      ref={register({ required: true })}
                    />
                    {errors.company_name1 &&
                      errors.company_name1.type === "required" && (
                        <p>Please enter your Company Name</p>
                      )}
                  </div>
                  <div className="form-control">
                    <label>
                      Phone Number<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="phone_number1"
                      id="phone_number1"
                      maxLength={10}
                      ref={register({
                        required: true,
                        pattern: /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/,
                      })}
                    />
                    {errors.phone_number1 &&
                      errors.phone_number1.type === "required" && (
                        <p>Please enter your Company Phone No</p>
                      )}
                    {errors.phone_number1 &&
                      errors.phone_number1.type === "pattern" && (
                        <p>Please enter valid phone number</p>
                      )}
                  </div>
                </div>
                <div className="tow-col-grid-items">
                  <div className="form-control">
                    <label>
                      Street Address<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="street_address1"
                      id="street_address1"
                      ref={register({ required: true })}
                    />
                    {errors.street_address1 &&
                      errors.street_address1.type === "required" && (
                        <p>Please enter your Company Phone No</p>
                      )}
                  </div>
                </div>
              </div>
              <div className="four-col-grid">
                <div className="form-control">
                  <label>City*</label>
                  <input
                    type="text"
                    name="city2"
                    id="city2"
                    ref={register({ required: true })}
                  />
                  {errors.city2 && errors.city2.type === "required" && (
                    <p>Please enter your Company city</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    State / Province / Region<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="state2"
                    id="state2"
                    ref={register({ required: true })}
                  />
                  {errors.state2 && errors.state2.type === "required" && (
                    <p>Please enter your Company State</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Postal / Zip Code<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="postal"
                    id="postal"
                    maxLength={5}
                    ref={register({
                      required: true,
                      pattern: /^[0-9\b]{5}$/,
                    })}
                  />

                  {errors.postal && errors.postal.type === "required" && (
                    <p>Please enter your Zip Code</p>
                  )}
                  {errors.postal && errors.postal.type === "pattern" && (
                    <p>Please enter valid zip code</p>
                  )}
                </div>
                <div className="form-control">
                  <label>
                    Country<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="country2"
                    id="country2"
                    ref={register({ required: true })}
                  />
                  {errors.country2 && errors.country2.type === "required" && (
                    <p>Please enter your Company Country</p>
                  )}
                </div>
              </div>
              <div className="tow-col-grid">
                <div className="tow-col-grid-items">
                  <div className="form-control">
                    <label>
                      Start Date<span>*</span>
                    </label>
                    <input
                      type="date"
                      name="start_date"
                      id="start_date"
                      ref={register({ required: true })}
                    />
                    {errors.start_date &&
                      errors.start_date.type === "required" && (
                        <p>Please enter start date</p>
                      )}
                  </div>
                  <div className="form-control">
                    <label>
                      End Date<span>*</span>
                    </label>
                    <input
                      type="date"
                      name="end_date"
                      id="end_date"
                      ref={register({ required: true })}
                    />
                    {errors.end_date && errors.end_date.type === "required" && (
                      <p>Please enter end date</p>
                    )}
                  </div>
                </div>
                <div className="one-col-grid">
                  <div className="tow-col-grid-items">
                    {isAllowedSalaryField == 0 &&
                      <div className="form-control">
                        <label>
                          Salary<span>*</span>
                        </label>
                        <input
                          type="text"
                          name="salary"
                          id="salary"
                          ref={register({ required: true })}
                        />
                        {errors.salary && errors.salary.type === "required" && (
                          <p>Please enter last salary</p>
                        )}
                      </div>
                    }
                    <div className="form-control display-none">
                      <label>
                        Select Age<span>*</span>
                      </label>

                      <select
                        value={selectedCurrentAge}
                        name="current_age"
                        onChange={handleSelectedCurrentAge}
                        className="form-control show-items dropdown-align select-dropdown"
                        ref={register({
                          required: false,
                        })}
                      >
                        <option value={""}>{"Select.."}</option>
                        {getAgeList()}
                      </select>
                      {errors.current_age &&
                        errors.current_age.type === "required" && (
                          <p>Please Select Age?</p>
                        )}
                    </div>
                    <div className={`form-control ${clientId === '266' ? "" : "display-none"}`}>
                      <label>
                        Are you over the age of 18?<span>*</span>(Yes or No){" "}
                      </label>

                      <select
                        value={selectedAgeOption}
                        name="are_you_over_the_age_of_18"
                        onChange={handleSelectAgeOption}
                        className="form-control show-items dropdown-align select-dropdown"
                        ref={register({
                          required: clientId === '266' ? true : false,
                        })}
                      >
                        <option value={""}>{"Select.."}</option>
                        {optionList.length > 0 &&
                          optionList.map((option) => {
                            return (
                              <option value={option.value}>{option.title}</option>
                            );
                          })}
                      </select>
                      {errors.are_you_over_the_age_of_18 &&
                        errors.are_you_over_the_age_of_18.type === "required" && (
                          <p>
                            Please select age option
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="tow-col-grid"></div>
              </div>
              <div className="sec-col-grid">
                <div>
                  <div className="form-control leaving-text">
                    <label>Reason for Leaving</label>
                    <textarea
                      name="reason_for_leaving"
                      id="reason_for_leaving"
                      ref={register}
                    ></textarea>
                  </div>
                  <div className="form-control">
                    <label>
                      How many jobs have you had in the last 5 years not listed
                      above<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above"
                      id="how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above"
                      ref={register({ required: true })}
                    />

                    {errors.how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above &&
                      errors
                        .how_many_jobs_have_you_had_in_the_last_5_years_not_listed_above
                        .type === "required" && (
                        <p>
                          Please enter how many jobs have you had in the last 5
                          years not listed above
                        </p>
                      )}
                  </div>
                </div>
                <div className="form-control reason-text">
                  <label>
                    Please indicate the job duties you have performed which
                    relate to the position for which you are applying
                    <span>*</span>
                  </label>
                  <textarea
                    name="the_job_duties"
                    id="the_job_duties"
                    ref={register}
                  ></textarea>
                </div>
              </div>
              <div className="three-col-grid">
                <div className="form-control">
                  <label>
                    Are you eligible for employment in the United States?
                    <br /> (Yes or No){" "}
                  </label>

                  <select
                    value={selectedEmployment}
                    name="are_you_eligible_for_employment_in_the_united_states"
                    onChange={handleSelectEmployment}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {optionList.length > 0 &&
                      optionList.map((option) => {
                        return (
                          <option value={option.value}>{option.title}</option>
                        );
                      })}
                  </select>
                  {errors.did_you_earn_a_degree &&
                    errors.did_you_earn_a_degree.type === "required" && (
                      <p>
                        Please Select for eligible for employment in the United
                        States or not
                      </p>
                    )}
                </div>
                <div className="form-control">
                  <label>
                    Days Available for Work<span>*</span>
                    <br />
                    (Mon,Tue,Wed,Thu,Fri,Sat,Sun)
                  </label>
                  <input
                    type="text"
                    name="days_available_for_work"
                    id="days_available_for_work"
                    ref={register({ required: true })}
                  />
                  {errors.days_available_for_work &&
                    errors.days_available_for_work.type === "required" && (
                      <p>Please enter Days Available for Work</p>
                    )}
                </div>
                <div className="form-control">
                  <label>
                    Hours Available for Work<span>*</span> <br />
                    (Yes or No)
                  </label>
                  <input
                    type="text"
                    name="hours_available_for_work"
                    id="hours_available_for_work"
                    ref={register({ required: true })}
                  />
                  {errors.hours_available_for_work &&
                    errors.hours_available_for_work.type === "required" && (
                      <p>Please enter Hours Available for Work</p>
                    )}
                </div>
              </div>
              <div className="one-col-grid">
                <div className="form-control">
                  <label>Additional Notes</label>
                  <textarea
                    name="additional_notes"
                    id="additional_notes"
                    ref={register}
                  ></textarea>
                </div>
              </div>

              <div className="note-text">
                <p>
                  The information above is true and correct. I understand the
                  employers and persons listed in this application may also be
                  contacted for verification and references. I authorize the
                  verification of the information I have provided. I further
                  understand that any falsification, misrepresentation or
                  omissions may be grounds for termination of my employment with
                  this company at any time. I understand the acceptance of this
                  application and any other pre- employment processing by the
                  company does not signify that a job opening exists and in no
                  way is an offer of employment or a commitment of any kind by
                  this company. (Yes or No)<span>*</span>
                </p>

                <select
                  value={selectedInfo}
                  name="the_information"
                  onChange={handleSelectedinfo}
                  className="form-control show-items dropdown-align select-dropdown"
                  ref={register({
                    required: true,
                  })}
                >
                  <option value={""}>{"Select.."}</option>
                  {optionList.length > 0 &&
                    optionList.map((option) => {
                      return (
                        <option value={option.value}>{option.title}</option>
                      );
                    })}
                </select>
                {errors.did_you_earn_a_degree &&
                  errors.did_you_earn_a_degree.type === "required" && (
                    <span>Please Select Yes or No</span>
                  )}
              </div>
              <div className="four-col-grid">
                <div className="form-control">
                  <label>
                    Name<span>*</span>
                  </label>
                  <input
                    type="text"
                    name="name1"
                    id="name1"
                    ref={register({ required: true })}
                  />

                  {errors.name1 && errors.name1.type === "required" && (
                    <p>Please enter name</p>
                  )}
                </div>

                <div className="form-control">
                  <label>
                    Store Location<span>*</span>
                  </label>

                  <select
                    value={selectedStoreLocation}
                    name="selectStore"
                    onChange={handleSelectStoreLocation}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {storeList.length > 0 &&
                      storeList.map((store) => {
                        return (
                          <option value={store.ClientStoreName}>
                            {store.ClientStoreName}
                          </option>
                        );
                      })}
                  </select>
                  {errors.selectStore &&
                    errors.selectStore.type === "required" && (
                      <p>Please Select Store.</p>
                    )}
                </div>

                <div className="form-control">
                  <label>Job Applying For</label>
                  {clientId === "40" ? (
                    <>
                      <input
                        type="text"
                        name="jobId"
                        id="jobId"
                        onChange={handleJobid}
                        ref={register({ required: true })}
                      />

                      {errors.jobId && errors.jobId.type === "required" && (
                        <p>Please enter job title</p>
                      )}
                    </>
                  ) :
                    (
                      <>
                        <select
                          value={selectJobid}
                          name="jobId"
                          onChange={handleJobid}
                          className="form-control show-items dropdown-align select-dropdown"
                          ref={register({
                            required: false,
                          })}
                        >
                          <option value={""}>{"Select.."}</option>
                          {jobList &&
                            jobList.length > 0 &&
                            jobList.map((job) => {
                              return (
                                <option value={job.job_id}>{job.job_title}</option>
                              );
                            })}
                        </select>
                        {errors.jobId && errors.jobId.type === "required" && (
                          <p>Please Select Job.</p>
                        )}
                      </>
                    )}

                </div>

                <div className="form-control">
                  <label>
                    Date<span>*</span>
                  </label>
                  <input
                    type="date"
                    name="date2"
                    id="date2"
                    ref={register({ required: true })}
                  />
                  {errors.date2 && errors.date2.type === "required" && (
                    <p>Please Select date</p>
                  )}
                </div>
              </div>
              <div className="terms-and-conditions">
                <input
                  type="checkbox"
                  id="terms_condition"
                  checked={checkbox}
                />

                <label
                  htmlFor="Terms and Conditions"
                  onClick={() => onChangeoption()}
                >
                  <span>
                    I agree to the {headerSectionData.client_name}
                    <div className="text-danger">
                      <a
                        href="/custom-pages/terms-conditions"
                        rel="noreferrer"
                        target="_blank"
                      >
                        &nbsp;Terms and Conditions
                      </a>
                    </div>
                  </span>
                </label>
                {errors.terms_condition &&
                  errors.terms_condition.type === "required" && (
                    <p>Please Select checkbox</p>
                  )}
              </div>

              {employmentStatus ? (
                <span
                  className={
                    employmentStatus === "success"
                      ? "feedback-success"
                      : "feedback-error"
                  }
                >
                  {employmentMessage}
                </span>
              ) : (
                ""
              )}

              <div className="re-captcha" style={{ marginTop: "20px" }}>
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={your_recaptcha_site_key}
                  onChange={onChangeReCaptcha}
                />
                <span style={{ color: "red" }}>{captchaError}</span>
              </div>

              <div className="form-submit-button">
                <button
                  type="submit"
                  disabled={callFormSubmit}
                  className={callFormSubmit ? "disabled_Button" : ""}
                >
                  {callFormSubmit ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div >
      </div >
      {/* )
    } */}
    </>
  );
};
export default EmploymentForm;
