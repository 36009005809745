import React, { useState, useEffect } from "react";
import './catering-checkbox-group.scss'
import DownIcon from "assets/icons/bold-down-arrow.svg";
import { useDispatch } from 'react-redux';
import {
    handleUpdateCateringAndMealCartCustomOption
} from "redux/actions";

const CateringCheckBoxGroup = ({
    groupData,
    custom_options_arr,
    productIndex,
    setShowProductButton
}) => {
    const dispatch = useDispatch();
    const { values, title, is_required, title: label, is_add_option_price, id: groupId, max_selection, position } = groupData
    const [selectedOption, setSelectedOption] = useState([]);
    const [selectedOptionData, setSelectedOptionData] = useState([]);
    useEffect(() => {
        const defaultSelectedOption = custom_options_arr.filter(option => values.some(a => a.id === option.id)).map(el => el.id);
        const defaultSelectedOptionData = custom_options_arr.filter(option => values.some(a => a.id === option.id));
        setSelectedOptionData(defaultSelectedOptionData);
        setSelectedOption(defaultSelectedOption);
    }, [custom_options_arr]) // eslint-disable-line

    const selectedOptionIds = selectedOptionData.map(op => op.id)

    const handleChangeOption = (optionValue, option) => {
        const { id, option_id, price, value } = option

        let updatedIftCheckedList = selectedOption;

        if (updatedIftCheckedList.includes(optionValue)) {
            updatedIftCheckedList = updatedIftCheckedList.filter(
                (iftType) => iftType !== optionValue
            );
            setShowProductButton()
        } else {

            if (max_selection !== "0" && (updatedIftCheckedList.length <= (+max_selection - 1))) {
                updatedIftCheckedList.push(optionValue);
                setShowProductButton()
            }
            if (!+max_selection) {
                updatedIftCheckedList.push(optionValue);
                setShowProductButton()
            }

        }

        let updatedOptionList = selectedOptionData;
        if (selectedOptionIds.includes(id)) {
            updatedOptionList = updatedOptionList.filter(
                (iftType) => iftType.id !== id
            );
        } else {

            if (max_selection !== "0" && (updatedOptionList.length <= (+max_selection - 1))) {
                updatedOptionList.push({ id, is_add_option_price, label, option_id, price, value, position });
            }
            if (!+max_selection) {
                updatedOptionList.push({ id, is_add_option_price, label, option_id, price, value, position });
            }
        }

        setSelectedOption([...updatedIftCheckedList])
        setSelectedOptionData([...updatedOptionList])
        dispatch(handleUpdateCateringAndMealCartCustomOption({ [groupId]: [...updatedOptionList] }, productIndex, true))
    }

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };


    return (
        <div className="catering-checkbox-group">
            <p onClick={toggleMenu}><strong>{title}</strong>{is_required === "1" && <span>*</span>}&nbsp;{max_selection != "0" ? <span className="select-text">(Select up to {max_selection})</span> : ""}<div className={isOpen ? "arrow arrow-up" : "arrow arrow-down"}>
                <img src={DownIcon} alt="downIcon" />
            </div></p>
            <div className={isOpen ? "check-box-wrapper dropdown-show" : "check-box-wrapper dropdown-hide"}>
                <div className="catering-checkbox-group-item-list">
                    {values && values.length && values.map((option => {
                        return <div className="catering-item-with-checkbox" key={option.id}>
                            <input
                                type="checkbox"
                                id={option.id + productIndex}
                                onChange={() => handleChangeOption(option.id, option)}
                                checked={selectedOption.includes(option.id)}
                            />
                            <label htmlFor={option.id + productIndex}>
                                <span>{option.value}  {option.price !== "0.00" ? `- $${option.price}` : ""}</span>
                            </label>
                        </div>
                    }))}
                </div>
            </div>
        </div>
    );
};

export default CateringCheckBoxGroup;
