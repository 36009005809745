import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getCommonDetails } from "redux/actions";
import { selectCurrentStore } from "redux/actions/store";
import LocationIcon from "assets/icons/location.svg";
import DownIcon from "assets/icons/down.svg";
import useOnClickOutside from "hooks/useOnClickOutside";
import { getLocalStorageValue, setLocalStorageValue } from 'config/helper';

const StoreLocationModal = (props) => {
  const { isShowDeliveryPopup, onClose, selectedStore } = props;
  const [isShowSelectItems, setIsShowSelectItems] = useState(false);
  const [showStoreSelect, setStoreSelect] = useState(false);
  const clientId = getLocalStorageValue("RSAclient-id");
  const aogEndPointURL = getLocalStorageValue("api-end-point-aog");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const storeList = useSelector(({ store }) => store.storeListData);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn)

  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );


  const refSelect = useRef();
  const refDelivery = useRef();

  useOnClickOutside(refDelivery, () => onClose());
  useOnClickOutside(refSelect, () => setIsShowSelectItems(false));

  const handleStoreChange = (selectedStore) => {
    setIsShowSelectItems(false);
    dispatch(selectCurrentStore(selectedStore));
    setLocalStorageValue('selected-store-id', selectedStore.ClientStoreId);
    setLocalStorageValue("selected-initial-store-id", selectedStore.ClientStoreId)
    
  };

  const handleCloseModal = () => {
    if (!getLocalStorageValue("selected-initial-store-id")) {
      setLocalStorageValue("selected-initial-store-id", getLocalStorageValue("selected-store-id"));
    }
    if (clientId && aogEndPointURL) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: parseInt(selectedStore.ClientStoreId),
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getCommonDetails(body));
    }
    navigate("/");
    onClose();
  }

  return (
    <>
      {showStoreSelect && (
        <div className="modal-backdrop">
          <div className="modal store-selector-modal" ref={refDelivery}>
            <div className="store-selector-modal-header">
              <div className="logo-center-align">
                <img
                  src={headerSectionData.rsa_client_logo}
                  alt={headerSectionData.image_alt_text}
                />
              </div>
            </div>
            <div className="store-selector-modal-body">
              <div className="input-label"><strong>{headerSectionData ? headerSectionData.store_list_pop_lable : 'Select Store'}</strong></div>
              <div className="input">
                <div
                  ref={refSelect}
                  className="store-modal-select"
                  onClick={() => setIsShowSelectItems(!isShowSelectItems)}
                >
                  <div>
                    <div className="store-modal-location-icon">
                      <img src={LocationIcon} alt="LocationIcon" />
                    </div>
                    <div className="store-modal-down-icon">
                      <img src={DownIcon} alt="DownIcon" />
                    </div>
                    <span>
                      {selectedStore
                        ? selectedStore.ClientStoreName
                        : "Select Store"}
                    </span>
                  </div>
                  <div
                     className={
                      isShowSelectItems
                        ? "dropdown dropdown-show"
                        : "dropdown dropdown-hidden"
                    }
                  >
                    <div className="list-menu">
                      <ul>
                        {storeList &&
                          storeList.map((el) => {
                            return (
                              <li
                                className={
                                  el.ClientStoreId ===
                                    selectedStore?.ClientStoreId
                                    ? "selected"
                                    : ""
                                }
                                key={el.ClientStoreId}
                                onClick={() => handleStoreChange(el)}
                              >
                                {el.ClientStoreName}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={
                  !!getLocalStorageValue("selected-store-id") && selectedStore
                    ? "continue-shopping-btn"
                    : "disable-btn continue-shopping-btn"
                }
              >
                <button onClick={handleCloseModal}>
                  <span>Continue</span>
                  <i className="fa-solid fa-right-long"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isShowDeliveryPopup && !showStoreSelect && (
        <div className="store-main-wrap">
          <div className="store-selector-modal">
            <div className="modal-content">
              {/* <button className="close-btn" onClick={onClose}>
                &times;
              </button> */}
              <h2>Store Location</h2>
              <p className="current-store-text">You are currently shopping here:</p>
              <div className="store-details">
                <div className="store-logo-wrap">
                  <img src={headerSectionData.rsa_client_logo} alt={headerSectionData.image_alt_text} className="store-logo" />
                </div>
                <div className="store-info-wrapper">
                  <div className="store-info">
                    <h3>{selectedStore?.ClientStoreName}</h3>
                    <p>{selectedStore?.AddressLine1}</p>
                    <p>{selectedStore?.City+", "+selectedStore?.StateName+" - "+selectedStore?.ZipCode}</p>
                    {/* <p>{selectedStore?.AddressLine2}</p> */}
                    <p><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 512 512"><path fill="currentColor" d="M478.94 370.14c-5.22-5.56-23.65-22-57.53-43.75c-34.13-21.94-59.3-35.62-66.52-38.81a3.83 3.83 0 0 0-3.92.49c-11.63 9.07-31.21 25.73-32.26 26.63c-6.78 5.81-6.78 5.81-12.33 4c-9.76-3.2-40.08-19.3-66.5-45.78s-43.35-57.55-46.55-67.3c-1.83-5.56-1.83-5.56 4-12.34c.9-1.05 17.57-20.63 26.64-32.25a3.83 3.83 0 0 0 .49-3.92c-3.19-7.23-16.87-32.39-38.81-66.52c-21.78-33.87-38.2-52.3-43.76-57.52a3.9 3.9 0 0 0-3.89-.87a322.4 322.4 0 0 0-56 25.45A338 338 0 0 0 33.35 92a3.83 3.83 0 0 0-1.26 3.74c2.09 9.74 12.08 50.4 43.08 106.72c31.63 57.48 53.55 86.93 100 133.22S252 405.21 309.54 436.84c56.32 31 97 41 106.72 43.07a3.86 3.86 0 0 0 3.75-1.26A337.7 337.7 0 0 0 454.35 430a322.7 322.7 0 0 0 25.45-56a3.9 3.9 0 0 0-.86-3.86" /></svg>&nbsp;{selectedStore?.StorePhoneNumber}</p>
                    {/* <p className="email-text"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m-.4 4.25l-7.07 4.42c-.32.2-.74.2-1.06 0L4.4 8.25a.85.85 0 1 1 .9-1.44L12 11l6.7-4.19a.85.85 0 1 1 .9 1.44" /></svg>{selectedStore?.StoreEmail}</p> */}
                  </div>
                  <button className="make-home-store-btn">Select a different location</button>
                  <div
                  ref={refSelect}
                  className="store-modal-select"
                  onClick={() => setIsShowSelectItems(!isShowSelectItems)}
                >
                    <div className="store-modal-location-icon">
                      <img src={LocationIcon} alt="LocationIcon" />
                    </div>
                    <div className="store-modal-down-icon">
                      <img src={DownIcon} alt="DownIcon" />
                    </div>
                    <span>
                      {selectedStore
                        ? selectedStore.ClientStoreName
                        : "Select Store"}
                    </span>
                  <div
                     className={
                      isShowSelectItems
                        ? "dropdown dropdown-show"
                        : "dropdown dropdown-hidden"
                    }
                  >
                    <div className="list-menu">
                      <ul>
                        {storeList &&
                          storeList.map((el) => {
                            return (
                              <li
                                className={
                                  el.ClientStoreId ===
                                    selectedStore?.ClientStoreId
                                    ? "selected"
                                    : ""
                                }
                                key={el.ClientStoreId}
                                onClick={() => handleStoreChange(el)}
                              >
                                {el.ClientStoreName}
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
                </div>
                <div className="modal-actions">
                  <button className="continue-btn" onClick={handleCloseModal}>
                    Continue to shop here
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StoreLocationModal;