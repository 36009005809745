import React, { useEffect, useRef, useState } from "react";
import Logo from "assets/logo/footer-logo.svg";
import { Link, useLocation, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getOrderDetails,
  getCateringMealsOrderDetails,
  cancelOrder,
} from "redux/actions";
import ReactToPrint from "react-to-print";
import ComponentToPrintTheme2 from "./ComponentToPrintTheme2";
import ConfirmationModalTheme2 from "../AddressBookTheme2/ConfirmationModalTheme2";
import { getLocalStorageValue } from "config/helper";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import ProductImage from "../../../assets/images/preview-product.png";
import "./order-details-theme2.scss";
import { getWidth } from "react-slick/lib/utils/innerSliderUtils";

const initialState = {
  store_pickup_status_list: [],
  delivery_status_list: [],
};

const MyOrderDetailsTheme2 = () => {
  const { orderid, invoiceType, mealKitId } = useParams();
  const componentRef = useRef();
  const location = useLocation();
  const [iState, updateState] = useState(initialState);
  const [item, setItem] = useState();

  const { store_pickup_status_list, delivery_status_list } = iState;

  const dispatch = useDispatch();

  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");

  const { orderdetailsData: singleOrderData, cancelOrderLoading } = useSelector(
    ({ customer }) => customer
  );
  const { order_cancelled_flag = "0" } = singleOrderData;
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const { facebook_url, twitter_url, rsa_client_logo } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );
  const order_status_list = location?.state?.OrderStatusList;
  const [isShowConfirmationPopup, setShowConfirmationPopup] = useState(false);

  useEffect(() => {
    if (invoiceType === "catering-meal") {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: storeId,
        meal_order_id: mealKitId,
        AppName: isUserLoggedIn ? "shop" : "",
        Version: isUserLoggedIn ? "1" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getCateringMealsOrderDetails(body));
    } else {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: storeId,
        order_id: orderid,
        AppName: isUserLoggedIn ? "shop" : "",
        Version: isUserLoggedIn ? "1" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        DeviceType: isUserLoggedIn ? "web" : "",
      };
      dispatch(getOrderDetails(body));
    }
  }, [dispatch, clientId, userToken, storeId, orderid, invoiceType, mealKitId]); // eslint-disable-line

  const dateFormat = (inputDate, format) => {
    const date = new Date(inputDate);

    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    format = format.replace("MM", month.toString().padStart(2, "0"));

    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  };

  const formatPhoneNumber = (str) => {
    let cleaned = ("" + str).replace(/\D/g, "");

    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }

    return null;
  };

  const handleShowConfirmationPopup = () => {
    setShowConfirmationPopup(true);
  };

  const handleCancelOrder = () => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: storeId,
      order_id: orderid,
      customer_cancel_notes: "General Inquiry",
      AppName: isUserLoggedIn ? "shop" : "",
      Version: isUserLoggedIn ? "1" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    dispatch(cancelOrder(body)).then((res) => {
      if (invoiceType === "catering-meal") {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          meal_order_id: mealKitId,
          AppName: isUserLoggedIn ? "shop" : "",
          Version: isUserLoggedIn ? "1" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          DeviceType: isUserLoggedIn ? "web" : "",
        };
        dispatch(getCateringMealsOrderDetails(body));
      } else {
        const body = {
          RSAClientId: clientId,
          ClientStoreId: storeId,
          order_id: orderid,
          AppName: isUserLoggedIn ? "shop" : "",
          Version: isUserLoggedIn ? "1" : "",
          member_number: isUserLoggedIn ? memberNumber : "",
          user_token: isUserLoggedIn ? userToken : "",
          DeviceType: isUserLoggedIn ? "web" : "",
        };
        dispatch(getOrderDetails(body));
      }
    });
  };

  const backToAccountUrl =
    invoiceType === "catering-meal"
      ? "/myaccount/cateringMealsOrders"
      : "/myaccount/myOrder";

  const breadcrumbList = [{ lable: "Order Details Checkout", link: "" }];

  useEffect(() => {
    const newState = { ...iState };
    if (order_status_list) {
      newState.store_pickup_status_list = order_status_list?.filter((item) => {
        if (
          item.ecom_order_status_id == "1" ||
          item.ecom_order_status_id == "2" ||
          item.ecom_order_status_id == "3" ||
          item.ecom_order_status_id == "4" ||
          item.ecom_order_status_id == "5" ||
          item.ecom_order_status_id == "6" ||
          item.ecom_order_status_id == "7"
        ) {
          return item;
        }
      });

      newState.delivery_status_list = order_status_list?.filter((item) => {
        if (
          item.ecom_order_status_id == "1" ||
          item.ecom_order_status_id == "2" ||
          item.ecom_order_status_id == "3" ||
          item.ecom_order_status_id == "4" ||
          item.ecom_order_status_id == "5" ||
          item.ecom_order_status_id == "12" ||
          item.ecom_order_status_id == "13" ||
          item.ecom_order_status_id == "14" ||
          item.ecom_order_status_id == "7"
        ) {
          return item;
        }
      });
    }
    updateState(newState);
  }, [order_status_list]);

  useEffect(() => {
    if (singleOrderData) {
      setItem(singleOrderData);
    }
  }, [singleOrderData]);

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title={`Order Details Checkout`}
      />
      {singleOrderData && (
        <div className="order-details-theme2">
          <div style={{ display: "none" }}>
            <ComponentToPrintTheme2 ref={componentRef} />
          </div>
          <div className="container">
            <div className="order-details-box-wrapper">
              <div className="oreder-details-title-list">
                <div className="order-details-page-title">
                  <h1>Order Details</h1>
                  {/* <button onClick={handleAddNewAddress}>Add</button> */}
                  <span>
                    {Object.keys(singleOrderData).length > 0
                      ? dateFormat(
                        singleOrderData.order_placed_on.split(" ")[0],
                        "MM-dd-yyyy"
                      )
                      : ""}
                  </span>
                  <span>
                    {singleOrderData.order_item_list?.length} Products
                  </span>
                </div>
                <div className="oreder-details-list">
                  <a href="/order-history">Back to List</a>
                </div>
              </div>
              <div className="order-card">
                <div className="order-list-step-wrap">
                  <div className="order-item-list-wrapper">
                    <div className="order-item-list">
                      {/* <div className="order-address-items">
                    <div className="order-address-book">
                      <div className="address-title">
                        billing address
                      </div>
                      <div className="address-description">
                        <div className="user-name info">
                        {singleOrderData.shipping_method_name}
                        </div>
                        <div className="user-email-box info">
                          email
                          <span>demo@gmail.com</span>
                        </div>
                        <div className="user-phone-box info">
                          phone
                          <span>{formatPhoneNumber(singleOrderData.StorePhoneNumber)}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="order-address-items">
                    <div className="order-address-book">
                      <div className="address-title">
                        Shipping address
                      </div>
                      <div className="address-description">
                        <div className="user-name info">
                        {singleOrderData.customer_first_name}{" "}
                    {singleOrderData.customer_last_name}
                        </div>
                        <div className="user-email-box info">
                          email
                          <span>{singleOrderData.customer_email}</span>
                        </div>
                        <div className="user-phone-box info">
                          phone
                          <span>{formatPhoneNumber(singleOrderData.order_perferred_mobile_number)}</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                      <div className="order-item-list-items">
                        <div className="order-address-items">
                          <div className="order-address-book">
                            <div className="address-title">Customer Details</div>
                            <div className="address-description">
                              <div className="user-name info">
                                {singleOrderData.customer_first_name}{" "}
                                {singleOrderData.customer_last_name}
                              </div>
                              <div className="user-email-box info">
                                Email
                                <span>{singleOrderData.customer_email}</span>
                              </div>
                              <div className="user-phone-box info">
                                Phone
                                <span>
                                  {formatPhoneNumber(
                                    singleOrderData.order_perferred_mobile_number
                                  )}
                                </span>
                              </div>
                              <div className="user-status-box info">
                                Status
                                <span>
                                  {Object.keys(singleOrderData).length > 0
                                    ? singleOrderData.order_status_name ||
                                    singleOrderData.status
                                    : ""}
                                </span>
                                {order_cancelled_flag === 1 && (
                                  <button
                                    className="cancel-order-button"
                                    disabled={cancelOrderLoading}
                                    onClick={handleShowConfirmationPopup}
                                  >
                                    {cancelOrderLoading
                                      ? "Cancelling order"
                                      : "Cancel Order"}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="order-address-items">
                          <div className="order-address-book">
                            <div className="address-title">shipping details</div>
                            <div className="address-description">
                              <div className="user-method-box info">
                                Shipping Method
                                <span>
                                  {singleOrderData.shipping_method_name}
                                </span>

                              </div>
                              <div className="user-picup-date info">
                                Phone
                                <span>
                                  {formatPhoneNumber(
                                    singleOrderData.StorePhoneNumber
                                  )}
                                </span>
                              </div>
                              <div className="user-phone-box info">
                                {singleOrderData.shipping_method ===
                                  "store_pickup"
                                  ? "Pickup Date"
                                  : "Delivery Date"}
                                <span>
                                  {dateFormat(
                                    singleOrderData.shipping_date,
                                    "MM-dd-yyyy"
                                  )}
                                </span>
                              </div>
                              <div className="user-time-box info">
                                {singleOrderData.shipping_method ===
                                  "store_pickup"
                                  ? "Pickup Time"
                                  : "Delivery Time"}
                                <span>{singleOrderData.shipping_time}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="order-address-items">
                          <div className="order-address-book">
                            <div className="address-title">store details</div>
                            <div className="address-description">

                              <div className="user-address-box info">
                                Address
                                <span>
                                  {singleOrderData.ClientStoreName},<br />
                                  {singleOrderData.AddressLine1},{" "}
                                  {singleOrderData.AddressLine2} <br />
                                  {singleOrderData.City},{" "}
                                  {singleOrderData.StateName} -{" "}
                                  {singleOrderData.ZipCode}, USA
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                    <div className="order-steps">
                      <div className="payment-title">
                        <span className="order-id">
                          ORDER Number :{" "}
                          <span>{singleOrderData.order_number}</span>
                        </span>

                      </div>
                      <div className="order-pyment-steps">
                        <p>
                          Subtotal:
                          <span>{`$${singleOrderData.amount}`}</span>
                        </p>
                        {singleOrderData?.department_tax_amount > 0 &&
                          <p>
                            Tax:
                            <span>{`$${singleOrderData.department_tax_amount}`}</span>
                          </p>
                        }
                        <p>
                          Additional Authorized Amount
                          <span>{`$${singleOrderData.additional_authorization_amount
                            ? singleOrderData.additional_authorization_amount
                            : "0.00"
                            }`}</span>
                        </p>
                        {singleOrderData?.discount_total > 0 &&
                          <p>
                            Discount:
                            <span>{`$${singleOrderData.discount_total}`}</span>
                          </p>
                        }
                        <p>
                          Shipping:
                          <span>{singleOrderData.shipping_method_name}</span>
                        </p>
                        <h6>
                          Total:
                          <span>{`$${singleOrderData.total_amount}`}</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="order-detail">
                    <div className="header-title">payment details</div>
                    <table>
                      <thead>
                        <tr>
                          <th>Method</th>
                          <th>Status</th>
                          <th>Transaction ID</th>
                          <th>Authorized amount</th>
                          <th>Final amount</th>
                          <th>Message</th>
                        </tr>
                      </thead>
                      <tbody>
                        {singleOrderData?.payment_list?.map((each) => (
                          <tr>
                            <td>{each.payment_method_name}</td>
                            <td>{each.payment_status_name}</td>
                            <td>{each.transaction_id}</td>
                            <td>{each.amount}</td>
                            <td>{each.final_amount}</td>
                            <td style={{ textTransform: "initial" }}>
                              {each.payment_message}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                  </div>
                  <div className="tracking-title">order tracking</div>
                  <div className="order-steps">
                    <ul>
                      {item?.shipping_method == "store_pickup"
                        ? store_pickup_status_list?.length > 0
                          ? store_pickup_status_list?.map((it, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  Number(it?.ecom_order_status_id) <=
                                    Number(item?.status)
                                    ? "complete"
                                    : ""
                                }
                              >
                                <span className="circle">
                                  {i + 1 < 10 ? `0${i + 1}` : i + 1}
                                </span>
                                <span className="text">
                                  {it?.ecom_order_status_name}
                                </span>
                              </li>
                            );
                          })
                          : ""
                        : delivery_status_list?.length > 0
                          ? delivery_status_list?.map((it, i) => {
                            return (
                              <li
                                key={i}
                                className={
                                  Number(it?.ecom_order_status_id) <=
                                    Number(item?.status)
                                    ? "complete"
                                    : ""
                                }
                              >
                                <span className="circle">
                                  {i + 1 < 10 ? `0${i + 1}` : i + 1}
                                </span>
                                <span className="text">
                                  {it?.ecom_order_status_name}
                                </span>
                              </li>
                            );
                          })
                          : ""}
                    </ul>
                  </div>
                </div>
                <div class="product-table">
                  <table className="table-responsive">
                    <thead>
                      <tr>
                        <th>Product</th>
                        <th>Price</th>
                        <th>Quantity</th>
                        <th>Subtotal</th>
                      </tr>
                    </thead>
                    <tbody className="product-body">
                      {Object.keys(singleOrderData).length > 0 &&
                        singleOrderData.order_item_list.map(
                          (order_item_list, index) => {
                            const unitPrice = order_item_list.price
                              ? +order_item_list.price
                              : 0;
                            const totalAmount = order_item_list.row_total
                              ? +order_item_list.row_total
                              : 0;
                            return (
                              <tr key={index}>
                                <td>
                                  <div className="product-image-wrap">
                                    <span class="product-image">
                                      {order_item_list?.image &&
                                        <img
                                          src={order_item_list?.image}
                                          alt="product_image"
                                        />
                                      }
                                    </span>
                                    <div className="product-title">
                                      {order_item_list.name}
                                    </div>
                                    {order_item_list.ebt_image !== "" && (
                                      <img
                                        src={order_item_list.ebt_image}
                                        alt="ebt_image"
                                        title="EBT Product"
                                        style={{
                                          width: "2rem",
                                          height: "2rem",
                                          marginLeft: "2rem",
                                        }}
                                      />
                                    )}
                                  </div>
                                </td>
                                <td>${+unitPrice.toFixed(2) ?? "0.00"}</td>
                                <td>{order_item_list.qty}</td>
                                <td>${totalAmount.toFixed(2) ?? "0.00"}</td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="invoice-footer-button">
              <ReactToPrint
                trigger={() => (
                  <button style={{ margin: "1rem" }}>
                    <i className="fa-solid fa-print"></i>
                    <span>Print and Download Invoice</span>
                  </button>
                )}
                content={() => componentRef.current}
              />
              {/* <ReactToPrint
              trigger={() => <button>
                <i className="fa-solid fa-download"></i>
                <span>Download Invoice</span>
              </button>}
              content={() => componentRef.current}
            /> */}

              {isShowConfirmationPopup && (
                <ConfirmationModalTheme2
                  isShow={isShowConfirmationPopup}
                  onClose={() => setShowConfirmationPopup(false)}
                  onClear={() => handleCancelOrder()}
                />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MyOrderDetailsTheme2;
