import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContactImage from "assets/images/contact.png";
import { useForm } from "react-hook-form";
import { userFeedback } from "redux/actions";
import { getLocalStorageValue } from "config/helper";
import ReCAPTCHA from "react-google-recaptcha";

import "./feedback-theme2.scss";

const FeedBackTheme2 = ({ storeList }) => {
  const recaptchaRef = React.createRef();
  const dispatch = useDispatch();
  const clientId = getLocalStorageValue("RSAclient-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const { feedbackStatus, feedbackMessage } = useSelector(
    ({ feedback }) => feedback
  );

  const commonDetailsDataLoading = useSelector(({ common }) => common);

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const [selectedContactReason, setSelectedContactReason] = useState("");

  const [selectedStore, setSelectedStore] = useState("");

  const [recaptchaValue, setRecaptchaValue] = useState("");

  const { register, handleSubmit, errors, reset } = useForm();

  const contactReasonList = useSelector(
    ({ contactus }) => contactus.contactReasonList
  );

  const handleSelectReason = (event) => {
    setSelectedContactReason(event.target.value);
  };

  const handleSelectStore = (event) => {
    setSelectedStore(event.target.value);
  };
  const headerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  const { your_recaptcha_site_key } = headerSectionData;
  const [captchaError, setCaptchaError] = useState("");
  const handleSubmitFeedbackForm = (data) => {
    const { firstName, lastName, emailId, phoneNo, message } = data;
    const body = {
      RSAClientId: clientId,
      ClientStoreId: parseInt(selectedStore),
      first_name: firstName,
      last_name: lastName,
      email_id: emailId,
      phone_number: phoneNo,
      contact_reason: selectedContactReason
        ? selectedContactReason
        : "",
      message: message ? message : "Test Message",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
    };
    if (recaptchaValue) {
      reset();
      setSelectedContactReason("");
      setSelectedStore("");
      setCaptchaError("");
      dispatch(userFeedback(body));
    } else {
      setCaptchaError("The captcha field is required");
    }

  };
  const onChangeReCaptcha = (value) => {
    setCaptchaError("");
    setRecaptchaValue(value);
  };

  return (
    <>
      <div className="feedback-section-theme2">
        <div className="container">
          <div className="grid">
            <div className="grid-items">
              <h1>Suggestions, Feedback, Recommendations?</h1>
              <p>Write to us and serve you better.</p>
              <form
                name="feedback"
                onSubmit={handleSubmit(handleSubmitFeedbackForm)}
              >
                <div className="input-grid">
                  <div className="form-control">
                    <label>
                      First Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      ref={register({
                        required: true,
                        pattern: /^[A-Za-z]+$/,
                      })}
                    />
                    {errors.firstName &&
                      errors.firstName.type === "required" && (
                        <span>Please enter your First Name</span>
                      )}
                    {errors.firstName &&
                      errors.firstName.type === "pattern" && (
                        <span>Please enter valid First Name</span>
                      )}
                  </div>
                  <div className="form-control">
                    <label>
                      Last Name<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="lastName"
                      id="lastName"
                      ref={register({ required: true, pattern: /^[A-Za-z]+$/ })}
                    />
                    {errors.lastName && errors.lastName.type === "required" && (
                      <span>Please enter your Last Name</span>
                    )}
                    {errors.lastName && errors.lastName.type === "pattern" && (
                      <span>Please enter valid Last Name</span>
                    )}
                  </div>
                </div>
                <div className="input-grid">
                  <div className="form-control">
                    <label>
                      Email Address<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="emailId"
                      id="emailId"
                      ref={register({
                        required: true,
                        pattern:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                      })}
                    />
                    {errors.emailId && errors.emailId.type === "required" && (
                      <span>Please enter email.</span>
                    )}
                    {errors.emailId && errors.emailId.type === "pattern" && (
                      <span>
                        The email address entered is invalid, please check the
                        formatting (e.g. email@domain.com)
                      </span>
                    )}
                  </div>
                  <div className="form-control">
                    <label>
                      Phone Number<span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="phoneNo"
                      id="phoneNo"
                      maxLength={10}
                      ref={register({
                        required: true,
                        pattern: /^[0-9]{10}$/,
                      })}
                    />

                    {errors.phoneNo && errors.phoneNo.type === "required" && (
                      <span>Please enter phone.</span>
                    )}
                    {errors.phoneNo && errors.phoneNo.type === "pattern" && (
                      <span>Please enter valid phone no.</span>
                    )}
                  </div>
                </div>
                {contactReasonList.length > 0 ? (
                  <div className="form-control">
                    <label>
                      {commonDetailsDataLoading.commonDetailsData.header_section
                        .contact_form_subject_field_title
                        ? commonDetailsDataLoading.commonDetailsData
                          .header_section.contact_form_subject_field_title
                        : "What are you contacting us about?"}
                    </label>
                    <select
                      value={selectedContactReason}
                      name="selectReason"
                      onChange={handleSelectReason}
                      className="form-control show-items dropdown-align select-dropdown"
                      ref={register({
                        required: true,
                      })}
                    >
                      <option value={""}>{"Select.."}</option>

                      {contactReasonList
                        .filter((list) => list.has_visible === "1")
                        .map((reasonlist) => {
                          return (
                            <option
                              value={reasonlist.name}
                              key={reasonlist.reason_id}
                            >
                              {reasonlist.name}
                            </option>
                          );
                        })}
                    </select>
                    {errors.selectReason &&
                      errors.selectReason.type === "required" && (
                        <span>Please Select reason.</span>
                      )}
                  </div>
                ) : (
                  ""
                )}

                <div className="form-control">
                  <label>
                    Select Store<span className="text-danger">*</span>
                  </label>

                  <select
                    value={selectedStore}
                    name="selectStore"
                    onChange={handleSelectStore}
                    className="form-control show-items dropdown-align select-dropdown"
                    ref={register({
                      required: true,
                    })}
                  >
                    <option value={""}>{"Select.."}</option>
                    {storeList.length > 0 &&
                      storeList.map((store) => {
                        return (
                          <option value={store.ClientStoreId}>
                            {store.ClientStoreName}
                          </option>
                        );
                      })}
                  </select>
                  {errors.selectStore &&
                    errors.selectStore.type === "required" && (
                      <span>Please Select Store.</span>
                    )}
                </div>

                <div className="form-control">
                  <label>Comment or message</label>
                  <textarea
                    name="message"
                    id="message"
                    ref={register}
                  ></textarea>
                </div>

                <div className="re-captcha">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={your_recaptcha_site_key}
                    onChange={onChangeReCaptcha}
                  />
                  <span style={{ color: "red" }}>{captchaError}</span>
                </div>

                {feedbackStatus ? (
                  <span
                    className={
                      feedbackStatus === "success"
                        ? "feedback-success"
                        : "feedback-error"
                    }
                  >
                    {feedbackMessage}
                  </span>
                ) : (
                  ""
                )}

                <div className="submit-button">
                  <button type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>

            <div className="grid-items">
              <img src={ContactImage} alt="ContactImage" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeedBackTheme2;
