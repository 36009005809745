import React, { useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import BreadcrumbDetailed from "components/Common/BreadcrumbDetailed";
import "./pharmacy-refills.scss";

const PharmacyRefills = () => {
  const recaptchaRef = useRef(null);
  const breadcrumbList = [{ lable: "Pharmacy Refills Form", link: "" }];

  // prescription
  const [prescriptionNumbers, setPrescriptionNumbers] = useState([""]);
  
  // add prescription number
  const handleAddPrescription = () => {
    setPrescriptionNumbers([...prescriptionNumbers, ""]);
  };

  // changes
  const handlePrescriptionChange = (index, value) => {
    const updatedNumbers = [...prescriptionNumbers];
    updatedNumbers[index] = value;
    setPrescriptionNumbers(updatedNumbers);
  };
  
  // remove prescription
  const handleRemovePrescription = (index) => {
    const updatedNumbers = [...prescriptionNumbers];
    updatedNumbers.splice(index, 1);
    setPrescriptionNumbers(updatedNumbers);
  };

  return (
    <>
      <BreadcrumbDetailed
        breadcrumbList={breadcrumbList}
        title="Subscriber to White's Perks"
      />
      <div className="pharmacy-section">
        <div className="container">
          <div className="title-wrap">
            <h1>Pharmacy Refills</h1>
          </div>
          <p>Please fill out the pharmacy refill form below and submit to have your order ready by <span>2 PM tomorrow.</span></p>
          <p>Do you have a Harding's Pharmacy Bonus Card? Each time you fill a prescription at our pharmacy, we will punch your card. After 12 punches, redeem your completed card at the grocery checkout for $10 off your next grocery purchase of $10 or more!</p>
          <div className="contact-content">
            <form>
              <div className="form-wrapper">
                <label className="form-label">Location<span>&nbsp;*</span></label>
                <select name="selected_store_location" className="input-label">
                  <option value="99">Fresh Value 4th Avenue</option>
                  <option value="100">Fresh Value Alabama City</option>
                  <option value="87">Fresh Value Athens</option>
                </select>
              </div>
              <div className="form-main">
                <label className="form-label">Names<span>&nbsp;*</span></label>
                <div className="form-wrapper-main">
                  <div className="form-wrapper-wrap">
                    <input type="email" name="first_name" required placeholder="First Name" className="input-label" />
                  </div>
                  <div className="form-wrapper-wrap">
                    <input type="email" name="last_name" required placeholder="Last Name" className="input-label" />
                  </div>
                </div>
              </div>
              <div className="form-main">
                <div className="form-wrapper-main">
              <div className="form-wrapper-wrap">
                <label className="form-label">Phone<span>&nbsp;*</span></label>
                <input type="text" name="phone_number" maxlength="10" required placeholder="Phone Number" className="input-label" />
              </div>
              <div className="form-wrapper-wrap">
                <label className="form-label">Email<span>&nbsp;*</span></label>
                <input type="email" name="email_id" required placeholder="Email" className="input-label" />
              </div>
                </div>
              </div>
              <div className="form-number-wrap">
                <div className="form-wrapper">
                  <label className="form-label">
                    Prescription Number<span>&nbsp;*</span>
                  </label>
                  {prescriptionNumbers.map((prescription, index) => (
                    <div className="prescription-nub-wrap">
                      <input
                        type="text"
                        name="prescription_number_list"
                        maxLength="10"
                        required
                        placeholder="Prescription Number"
                        className="input-label"
                        value={prescription}
                        onChange={(e) =>
                          handlePrescriptionChange(index, e.target.value)
                        }
                      />
                      {/* add new prescription  */}
                      {prescriptionNumbers.length < 5 && (
                        <div className="add-iocn" onClick={handleAddPrescription}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="currentColor"
                              d="M8 15c-3.86 0-7-3.14-7-7s3.14-7 7-7s7 3.14 7 7s-3.14 7-7 7M8 2C4.69 2 2 4.69 2 8s2.69 6 6 6s6-2.69 6-6s-2.69-6-6-6"
                            />
                            <path
                              fill="currentColor"
                              d="M8 11.5c-.28 0-.5-.22-.5-.5V5c0-.28.22-.5.5-.5s.5.22.5.5v6c0 .28-.22.5-.5.5"
                            />
                            <path
                              fill="currentColor"
                              d="M11 8.5H5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h6c.28 0 .5.22.5.5s-.22.5-.5.5"
                            />
                          </svg>
                        </div>
                      )}
                      {/* minus new prescription  */}
                      {prescriptionNumbers.length > 1 && (
                        <div
                          className="minus-iocn"
                          onClick={() => handleRemovePrescription(index)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fill="none"
                              stroke="currentColor"
                              d="M4 8h8m2.5 0a6.5 6.5 0 1 1-13 0a6.5 6.5 0 0 1 13 0Z"
                              strokeWidth="1"
                            />
                          </svg>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
              <div className="form-wrapper">
                <label className="form-label" for="message">message</label>
                <textarea className="form-control involved-message" name="message" id="message" cols="30" rows="5" placeholder="Enter Your Message"></textarea>
              </div>
              <Link to="/ApproveScreen" className="badge btn-danger mr-40">
                click here to view our privacy practices
              </Link>
              <ReCAPTCHA ref={recaptchaRef} sitekey="your-site-key" />
              <div className="form-wrapper">
                <button type="submit" className="signup-btn">Submit</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PharmacyRefills;