import React, { useState } from "react";
import Slider from "react-slick/lib/slider";
import ImageFadeIn from "react-image-fade-in";
import { NextArrow, PrevArrow } from "components/Common/Arrows";
import "./product-preview-slider-theme2.scss";

const ProductPreviewSliderTheme2 = ({ image, gallery }) => {
  const [activeSlide, setActiveSlide] = useState();

  const handleSlider = (slide) => {
    setActiveSlide(slide);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 10,
    slidesToShow: 4,
    vertical: true,
    verticalSwiping: true,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 1340,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
    ],
  };
  const hasChildImages = gallery.some(slide => slide.file);
  return (
    <div className="product-preview-slider-theme2">
      {hasChildImages && (
        <div className="child-images">
          <Slider {...settings}>
            {gallery.map((slide, index) => (
              <div key={index} className={activeSlide === slide.file ? "active procut-child-image" : "procut-child-image"}>
                <img
                  src={slide.file}
                  alt="ChildProductImage"
                  onClick={() => handleSlider(slide.file)}
                />
              </div>
            ))}
          </Slider>
        </div>
      )}
      <div className="selected-product">
        <ImageFadeIn src={activeSlide || image} />
      </div>
    </div>
  );
};
export default ProductPreviewSliderTheme2;
