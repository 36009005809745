import React, { useState, useEffect, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProductPreviewSliderTheme2 from "./ProductPreviewSliderTheme2";
import Dropdown from "components/Common/Dropdown";
import AddToMyListTheme2 from "components/AddToMyListTheme2";
import {
  addToCart,
  getRemoveProductToCartList,
  editProductToCartList,
  addToWishlist
} from "redux/actions";
import { useNavigate } from "react-router-dom";
import "./product-preview-theme2.scss";
import { getLocalStorageValue } from "config/helper";
import { debounce } from "lodash";
import LoadingImage from "../../../assets/icons/load.gif";

const ProductPreviewTheme2 = ({ productDetails, productInfo }) => {
  const {
    name,
    image,
    custom_options,
    sku,
    price1,
    price2,
    price3,
    price4,
    price5,
    mark_as_age_restricted_department_message,
    product_id,
    final_price,
    categories: { department_name = "" },
    stock_status,
    product_note,
    stores_inventory: {
      has_coupon = "0",
      digital_coupon_amount = "0",
      coupon_used_time = "0",
      ebt_item,
      ebt_image,
      is_discount_percentage,
      digital_coupon_details,
      digital_coupon_title,
      digital_coupon_item_qty_required,
      digital_coupon_other_details
    }
  } = productDetails;

  const gallery = productInfo?.Images?.map(image => ({ file: Object.values(image)[0] })) || [];
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowMyListDropdown, setShowMyListDropdown] = useState(false);
  const [isShowNewListModal, setShowNewListModal] = useState(false);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const clientId = getLocalStorageValue("RSAclient-id");
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const {
    productCartList,
    addProductToCartListLoading,
    wishListItemsStatus,
    wishListItemsMessage,
    wishListItemsLoading,
    updateProductToCartListLoading
  } = useSelector(({ cartWishlist }) => cartWishlist);

  const addedProcduct = productCartList?.find((product) => product.product_id === product_id);
  const custom_options_Arr = addedProcduct?.custom_options_arr;
  const itemId = addedProcduct?.item_id;
  const cartProductQuantity = addedProcduct?.qty;
  const [selectDropDownedOption, setSelectDropDownedOption] = useState([]);
  const [productQuantity, setProductQuantity] = useState(+cartProductQuantity || 1);
  const [isAddingProductToCart, setAddingProductToCart] = useState(false);

  const customOptions = custom_options.find((option) => option.type === "dropdown");
  const selectedCustomOption = customOptions?.values?.filter(
    (value) => value.value === selectDropDownedOption
  ) || [];

  useEffect(() => {
    if (customOptions?.values?.length > 0) {
      const defaultOption = customOptions.values.find((list) => list.is_default === "1");
      if (defaultOption) {
        setSelectDropDownedOption(defaultOption.value);
      }
    }
  }, []);

  const handleChangeDropDownOption = (_, name) => {
    setLoader(false);
    const selectedOption = customOptions?.values?.filter((value) => value.value === name);
    const formattedOption = selectedOption?.map((each) => ({
      title: each?.value,
      value: each?.value
    }));
    setSelectDropDownedOption(name);
    handleUpdateProductToCart(productQuantity, product_id, itemId, selectedOption, updateProductNote);
  };

  const [loader, setLoader] = useState(false);
  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
    setLoader(true);
    handleUpdateProductToCart(productQuantity + 1, product_id, itemId, selectedCustomOption, updateProductNote);
  };
  const handleDecrementCount = () => {
    setLoader(true);
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
      handleUpdateProductToCart(productQuantity - 1, product_id, itemId, selectedCustomOption, updateProductNote);
    } else {
      addedProcduct && handleRemoveProductToCart(itemId);
      setProductQuantity(1);
    }
  };

  const handleAddToWishlist = (list_id) => {
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      qty: productQuantity,
      list_id
    };
    isUserLoggedIn && dispatch(addToWishlist(body)).then(() => setShowMyListDropdown(false));
  };

  const handleAddToCart = () => {
    setLoader(false);
    if (!isUserLoggedIn) {
      navigate("/login");
    }
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      qty: productQuantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: updateProductNote || "",
      custom_options_arr: selectedCustomOption || []
    };
    setAddingProductToCart(true);
    isUserLoggedIn && dispatch(addToCart(body)).then(() => setAddingProductToCart(false));
  };

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getRemoveProductToCartList(body));
  };

  const handleUpdateProductToCart = (quantity, product_id, item_id, selectedOption, productNoteVal) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id,
      item_id,
      qty: quantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note: productNoteVal || "",
      custom_options_arr: selectedOption || selectedCustomOption || []
    };
    quantity >= 1 && dispatch(editProductToCartList(body));
  };

  const [updateProductNote, setUpdateProductNote] = useState(product_note ?? "");

  useEffect(() => {
    if (product_note !== "") {
      setUpdateProductNote(product_note);
    }
  }, [product_note]);

  const handleChangeProductNote = (value) => {
    setLoader(false);
    setUpdateProductNote(value);
    updateProductNoteResult.current(value, selectedCustomOption);
  };

  const updateProductNoteResult = useRef(
    debounce((value, selectedOption) => {
      handleUpdateProductToCart(productQuantity, product_id, itemId, selectedOption, value);
    }, 500)
  );

  const { allow_ecom_cart_and_checkout, allow_ecom_shown_price } = useSelector(
    ({ common }) => common.commonDetailsData.header_section || {}
  );

  return (
    <div className="product-preview-section-theme2">
      <div className="container">
        <div className="grid">
          <div className="grid-items grid-product-slider grid-width">
            <div>
              <ProductPreviewSliderTheme2 image={image} gallery={gallery} />
            </div>
          </div>
          <div className="grid-items grid-items grid-width desc-items">
            <div className="product-title-sec">
              <h1>{name}</h1>
              {clientId != "40" && <div className="stock-lable">In Stock</div>}
            </div>
            <div className="final-price">
              {allow_ecom_shown_price === "1" &&
                <div className="price">
                  {final_price > 0 ? (
                    <>
                      <h2 className={`final-product-price ${final_price > 0 ? "hidden" : ""}`}>
                        {price5}
                      </h2>
                      <span className="main-price">{price1}</span>
                    </>
                  ) : (
                    <h2 className="price">{price1}</h2>
                  )}
                  {ebt_item === "1" && <img src={ebt_image} alt="ebt_image" />}
                </div>
              }
              {+productDetails.stores_inventory.is_onsale > 0 && (
                <div className="product-card-onsale-lable">On&nbsp;Sale</div>
              )}
            </div>
            <div className="product-category">
              <p>
                Category: <span>{department_name}</span>
              </p>
            </div>
            <div className="department-code">
              {productDetails.like_code ? (
                <p>
                  Like Code: <span>{productDetails.like_code}</span>
                </p>
              ) : (
                <p>
                  UPC: <span>{sku}</span>
                </p>
              )}
            </div>
            {allow_ecom_shown_price === "1" &&
              <div className="other-price-details">
                {price2 && <p>{price2}</p>}
                {price3 && <p>{price3}</p>}
                {price4 && <p>{price4}</p>}
              </div>
            }
            <div className="coupon-pdp-used-time-wrapper">
              {+has_coupon > 0 && (
                <div className="flex-coupon">
                  {is_discount_percentage === "0" && digital_coupon_amount > 0 ? (
                    <div className="coupon-design">
                      <p>
                        Digital Coupon:
                        <span>
                          {is_discount_percentage === "0" ? "$" : ""}
                          {`${digital_coupon_amount}`}
                          {is_discount_percentage === "1" ? "%" : ""}
                        </span>
                      </p>
                    </div>
                  ) : (
                    is_discount_percentage === "1" && (
                      <div className="coupon-design">
                        <p>
                          Digital Coupon:
                          <span>
                            {+digital_coupon_amount === 100 ? " FREE* " : ""}
                            {is_discount_percentage === "0" ? "$" : ""}
                            {`${digital_coupon_amount}`}
                            {is_discount_percentage === "1" ? "%" : ""}
                          </span>
                        </p>
                      </div>
                    )
                  )}
                  {digital_coupon_item_qty_required > 1 && (
                    <div className="offer-price">
                      <div className="offer-text">
                        when you buy {digital_coupon_item_qty_required}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {coupon_used_time > 1 && +has_coupon > 0 && (
                <div className="coupon-used-time-ribbon coupon-used-pdp-item">
                  <div className="coupon-used-time">
                    <p>
                      <span>Use {coupon_used_time}X</span>
                    </p>
                  </div>
                </div>
              )}
            </div>
            {digital_coupon_title && digital_coupon_details && (
              <span className="coupon-title">
                {digital_coupon_title + " - " + digital_coupon_details}
              </span>
            )}
            <br />
            {digital_coupon_other_details && (
              <span className="coupon-title">
                {digital_coupon_other_details}
              </span>
            )}

            {/* product description page list align with tag when add cart not visible 20-12-2024 */}
            <div className={`add-product-option ${allow_ecom_cart_and_checkout === "0" ? "list-position" : ""}`}>
              {allow_ecom_cart_and_checkout === "1" && (
                <>
                  <div className="couter-button">
                    {updateProductToCartListLoading && loader ? (
                      <div className="silder-right">
                        <img style={{ width: "40px" }} src={LoadingImage} alt="loadimg" />
                      </div>
                    ) : (
                      <div className="silder-right">
                        <button onClick={handleDecrementCount} title="Click to decrease the qty">
                          -
                        </button>
                        {(addedProcduct?.qty) || productQuantity}
                        <button onClick={handleIncrementCount} title="Click to increase the qty">
                          +
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="add-to-cart">
                    <button
                      onClick={handleAddToCart}
                      disabled={stock_status === "0" || addedProcduct}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="add-to-cart-icon"
                        width="18"
                        height="18"
                        viewBox="0 0 14 11.575">
                        <g
                          id="Group_111"
                          data-name="Group 111"
                          transform="translate(-132.997 -1553.667)">
                          <g
                            id="Group_111-2"
                            data-name="Group 111"
                            transform="translate(133.997 1554.667)">
                            <path
                              id="Subtraction_1"
                              data-name="Subtraction 1"
                              d="M9.539,8.826a.244.244,0,0,1-.127-.039H4.7a.227.227,0,0,1-.254,0c-.046,0-.1,0-.16,0H4.038a2.346,2.346,0,0,1-.319-.018,1.075,1.075,0,0,1-.883-.851,1.1,1.1,0,0,1,.516-1.173.123.123,0,0,0,.074-.165C2.988,4.55,2.561,2.548,2.2.858,2.186.788,2.158.766,2.085.766H1.162c-.19,0-.441,0-.693.006H.45A.435.435,0,0,1,0,.5V.306A.359.359,0,0,1,.4,0L1.463,0h.919a.431.431,0,0,1,.473.391c.042.188.083.379.122.565l0,.01.014.066.045.213c.046.212.046.212.253.212h8.277a.628.628,0,0,1,.191.022A.33.33,0,0,1,12,1.788v.17a6.265,6.265,0,0,0-.19.636c-.022.085-.044.168-.066.251-.276.993-.546,1.972-.845,3.058L10.6,7a.375.375,0,0,1-.425.328H3.895a.4.4,0,0,0-.118.014.365.365,0,0,0-.26.384.361.361,0,0,0,.334.33l.065,0h6.293a.49.49,0,0,1,.1.007.364.364,0,0,1,.287.386.357.357,0,0,1-.341.334c-.134,0-.269,0-.4,0H9.666A.243.243,0,0,1,9.539,8.826ZM6.859,6.6l3.008,0a.122.122,0,0,0,.142-.109c.2-.723.394-1.436.612-2.227l.224-.812c.112-.407.223-.813.336-1.224H3.218c0,.006,0,.01,0,.014a.077.077,0,0,0,0,.017l.147.683.056.261c.232,1.083.472,2.2.706,3.3.018.086.06.093.128.093Z"
                              transform="translate(0 0)"
                              fill="#fff"
                              stroke="rgba(0,0,0,0)"
                              stroke-miterlimit="10"
                              stroke-width="2"></path>
                            <path
                              id="Path_63"
                              data-name="Path 63"
                              d="M369.843,385.073h.215a.93.93,0,0,1,.612.366.858.858,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C369.807,385.085,369.828,385.09,369.843,385.073Z"
                              transform="translate(-365.426 -376.279)"
                              fill="#fff"></path>
                            <path
                              id="Path_64"
                              data-name="Path 64"
                              d="M581.767,385.073h.215a.93.93,0,0,1,.611.366.857.857,0,0,1,.1.874.833.833,0,0,1-.678.531.883.883,0,0,1-1.035-.828.9.9,0,0,1,.729-.927C581.731,385.085,581.752,385.09,581.767,385.073Z"
                              transform="translate(-571.964 -376.279)"
                              fill="#fff"></path>
                            <path
                              id="Path_66"
                              data-name="Path 66"
                              d="M399.355,415.569a.3.3,0,1,1,.3-.294A.3.3,0,0,1,399.355,415.569Z"
                              transform="translate(-394.799 -405.584)"
                              fill="#fff"></path>
                            <path
                              id="Path_67"
                              data-name="Path 67"
                              d="M611.574,415.273a.3.3,0,1,1-.3-.3A.3.3,0,0,1,611.574,415.273Z"
                              transform="translate(-601.704 -405.586)"
                              fill="#fff"></path>
                          </g>
                        </g>
                      </svg>
                      <svg
                        className="out-of-stock-icon"
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 16 16">
                        <path
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="m7.493.015l-.386.04c-1.873.187-3.76 1.153-5.036 2.579C.66 4.211-.057 6.168.009 8.253c.115 3.601 2.59 6.65 6.101 7.518a8.034 8.034 0 0 0 6.117-.98a8 8 0 0 0 3.544-4.904c.172-.701.212-1.058.212-1.887s-.04-1.186-.212-1.887C14.979 2.878 12.315.498 9 .064C8.716.027 7.683-.006 7.493.015m1.36 1.548a6.34 6.34 0 0 1 1.987.597c.698.34 1.18.686 1.747 1.253A5.956 5.956 0 0 1 13.84 5.16c.445.915.646 1.798.646 2.84a6.188 6.188 0 0 1-.66 2.867c-.172.351-.519.914-.681 1.105l-.055.065l-4.563-4.564L3.963 2.91l.065-.055c.191-.162.754-.509 1.105-.681a6.436 6.436 0 0 1 3.72-.611M7.48 8.534l4.56 4.561l-.067.053a7.66 7.66 0 0 1-1.106.68a6.76 6.76 0 0 1-1.987.616c-.424.065-1.336.065-1.76 0c-1.948-.296-3.592-1.359-4.627-2.993a7.502 7.502 0 0 1-.634-1.332a6.62 6.62 0 0 1-.189-3.584a6.767 6.767 0 0 1 1.096-2.388c.07-.095.133-.173.141-.173c.007 0 2.065 2.052 4.573 4.56"
                        />
                      </svg>
                      <span>
                        {addProductToCartListLoading && isAddingProductToCart
                          ? "Adding..."
                          : addedProcduct
                            ? "Added to cart"
                            : stock_status === "0"
                              ? "out of stock"
                              : "Add to Cart"}
                      </span>
                    </button>
                  </div>

                  {custom_options &&
                    custom_options.map((option) => {
                      return (
                        <div className="substitution">
                          <p>{option.title}</p>
                          <div className="select-substitition-dropdown">
                            <Dropdown
                              optionList={
                                option.values
                                  ? option.values.map((list) => {
                                    return {
                                      title: list.value,
                                      value: list.id
                                    };
                                  })
                                  : []
                              }
                              lable=""
                              value={selectDropDownedOption}
                              placeholder="Select Option"
                              defaultValue="Yes"
                              onChange={handleChangeDropDownOption}
                            />
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              <div className="link-button">
                <AddToMyListTheme2
                  isShowMyListDropdown={isShowMyListDropdown}
                  isShowNewListModal={isShowNewListModal}
                  setShowNewListModal={setShowNewListModal}
                  setShowMyListDropdown={setShowMyListDropdown}
                  handleApply={handleAddToWishlist}
                  loading={wishListItemsLoading}
                  title="Add to my list"
                  rsaMedaiId={productDetails?.rsamediaid ?? ""}
                />
              </div>
              {wishListItemsStatus && wishListItemsStatus === "error"
                ? wishListItemsMessage
                : ""}
            </div>
            {allow_ecom_cart_and_checkout === "1" &&
              <div className="product-textarea">
                <textarea
                  onChange={(e) => {
                    handleChangeProductNote(e.target.value);
                  }}
                  placeholder="Enter item notes or substitution notes..."
                  value={updateProductNote}></textarea>
              </div>
            }
            <div className="department-code-wrap">
              {productDetails.stores_inventory.aisle_number ? (
                <div className="department-code">
                  <div>
                    <p>
                      Aisle Number:{" "}
                      <span>{productDetails.stores_inventory.aisle_number}</span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {productDetails.stores_inventory.location_section ? (
                <div className="department-code">
                  <div>
                    <p>
                      Location Section:{" "}
                      <span>{productDetails.stores_inventory.location_section}</span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {productDetails.stores_inventory.location_shelf ? (
                <div className="department-code">
                  <div>
                    <p>
                      Location Shelf:{" "}
                      <span>{productDetails.stores_inventory.location_shelf}</span>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>

            {productDetails?.stores_inventory?.stock_qty_count && productDetails.stores_inventory.stock_qty_count > 0 ?
              (
                <>
                <div className="stock-available">
                  Available Quantity: <span>{productDetails.stores_inventory.stock_qty_count}</span>
                </div>
                </>
              )
              : ("")
            }
            {mark_as_age_restricted_department_message && (
              <div className="restricted">
                <p>{mark_as_age_restricted_department_message}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductPreviewTheme2;
