import {
  FETCH_PRODUCT_LIST_BY_DEPARTMENT,
  FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS,
  FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE,
  FETCH_FILTER_OPTIONS_LIST,
  FETCH_FILTER_OPTIONS_LIST_SUCCESS,
  FETCH_FILTER_OPTIONS_LIST_FAILURE,
  UPDATE_SORT_BY_VALUE,
  UPDATE_PAGE_LIMIT,
  UPDATE_IFT_CHECK_LIST,
  EMPTY_IFT_CHECK_LIST,
  UPDATE_ACTIVE_PAGE,
  FETCH_DEPARTMENT_TREE,
  FETCH_DEPARTMENT_TREE_SUCCESS,
  FETCH_DEPARTMENT_TREE_FAILURE,
  UPDATE_PRICE_VALUE,
  UPDATE_NODE_FUNCTIONAL_TEXT,
  UPDATE_NODE_FUNCTIONAL_LEVEL,
  SEARCH_DEPARTMENT_TREE,
  SEARCH_DEPARTMENT_TREE_SUCCESS,
  SEARCH_DEPARTMENT_TREE_FAILURE,
  RESET_FILTER_SIDEBAR,
  FETCH_PRODUCT_DETAILS,
  FETCH_PRODUCT_DETAILS_SUCCESS,
  FETCH_PRODUCT_DETAILS_FAILURE,
  FETCH_RELATED_PRODUCT_LIST,
  FETCH_RELATED_PRODUCT_LIST_SUCCESS,
  FETCH_RELATED_PRODUCT_LIST_FAILURE,
  UPDATE_FROM_LIST,
  UPDATE_PAST_ORDER_LIST,
  SAVE_CURRENT_DEPARTMENT,
  WEEKLY_SALES_SELECTED,
  UPDATE_DEPARTMENT_NAME,
  GET_PRODUCTS_UPC_WISE_REQUEST,
  GET_PRODUCTS_UPC_WISE_SUCCESS,
  GET_PRODUCTS_UPC_WISE_FAILURE,
  FETCH_PRODUCT_INFO,
  FETCH_PRODUCT_INFO_SUCCESS,
  FETCH_PRODUCT_INFO_FAILURE,
} from "redux/constants/actionTypes";

import axios from "config/axios";
import { analyticsProductSearch, analyticsProductTrack } from "./analytics";

// fetch Product List by department
export const fetchProductListByDepartment = (payload) => {
  return {
    type: FETCH_PRODUCT_LIST_BY_DEPARTMENT,
    payload: payload,
  };
};
export const fetchProductListByDepartmentSuccess = (payload) => {
  return {
    type: FETCH_PRODUCT_LIST_BY_DEPARTMENT_SUCCESS,
    payload: payload,
  };
};
export const fetchProductListByDepartmentFailure = (payload) => {
  return {
    type: FETCH_PRODUCT_LIST_BY_DEPARTMENT_FAILURE,
    payload: payload,
  };
};

export const getProductListByDepartment = (body) => async (dispatch) => {
  dispatch(fetchProductListByDepartment());
  axios
    .post("/AogGetProducts", body)
    .then((res) => {
      if (res.data.status === "success") {
        dispatch(fetchProductListByDepartmentSuccess(res.data));
        const request = {
          klevu_term: body.search,
          klevu_totalResults: res.data.data.length,
          klevu_typeOfQuery: res.data.typeOfSearch || "",
        };
        dispatch(analyticsProductSearch(request));
      } else {
        dispatch(
          fetchProductListByDepartmentSuccess({
            data: [],
            filters: res.data.filters,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(
        fetchProductListByDepartmentFailure({ error: error.data.message })
      );
    });
};

// fetach filter option list
export const fetchFilterOptionList = (payload) => {
  return {
    type: FETCH_FILTER_OPTIONS_LIST,
    payload: payload,
  };
};
export const fetchFilterOptionListSuccess = (payload) => {
  return {
    type: FETCH_FILTER_OPTIONS_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchFilterOptionListFailure = (payload) => {
  return {
    type: FETCH_FILTER_OPTIONS_LIST_FAILURE,
    payload: payload,
  };
};

export const getFilterOptionList = (body) => async (dispatch) => {
  dispatch(fetchFilterOptionList());

  axios
    .post("/AogFilterOptions", body)
    .then((res) => {
      dispatch(fetchFilterOptionListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchFilterOptionListFailure({ error: error.data.message }));
    });
};

// fetch Department tree
export const fetchDepartmentTree = (payload) => {
  return {
    type: FETCH_DEPARTMENT_TREE,
    payload: payload,
  };
};
export const fetchDepartmentTreeSuccess = (payload) => {
  return {
    type: FETCH_DEPARTMENT_TREE_SUCCESS,
    payload: payload,
  };
};
export const fetchDepartmentTreeFailure = (payload) => {
  return {
    type: FETCH_DEPARTMENT_TREE_FAILURE,
    payload: payload,
  };
};

export const getDepartmentTree = (body) => async (dispatch) => {
  dispatch(fetchDepartmentTree());
  axios
    .post("/AogGetDepartmentTreeViewV2", body)
    .then((res) => {
      dispatch(fetchDepartmentTreeSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchDepartmentTreeFailure({ error: error.data.message }));
    });
};

// fetch search Department tree

export const fetchSearchDeapartmentTree = (payload) => {
  return {
    type: SEARCH_DEPARTMENT_TREE,
    payload: payload,
  };
};
export const fetchSearchDeapartmentTreeSuccess = (payload) => {
  return {
    type: SEARCH_DEPARTMENT_TREE_SUCCESS,
    payload: payload,
  };
};
export const fetchSearchDeapartmentTreeFailure = (payload) => {
  return {
    type: SEARCH_DEPARTMENT_TREE_FAILURE,
    payload: payload,
  };
};

export const getSearchDepartmentTree = (body) => async (dispatch) => {
  dispatch(fetchSearchDeapartmentTree());
  axios
    .post("/AogGetDepartmentTreeViewV2", body)
    .then((res) => {
      dispatch(fetchSearchDeapartmentTreeSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(
        fetchSearchDeapartmentTreeFailure({ error: error.data.message })
      );
    });
};

// fetch Product details
export const fetchProductDetails = (payload) => {
  return {
    type: FETCH_PRODUCT_DETAILS,
    payload: payload,
  };
};
export const fetchProductDetailsSuccess = (payload) => {
  return {
    type: FETCH_PRODUCT_DETAILS_SUCCESS,
    payload: payload,
  };
};
export const fetchProductDetailsFailure = (payload) => {
  return {
    type: FETCH_PRODUCT_DETAILS_FAILURE,
    payload: payload,
  };
};

export const getProductDetails = (body) => async (dispatch) => {
  dispatch(fetchProductDetails());
  axios
    .post("/AogGetProductsDetails", body)
    .then((res) => {
      dispatch(fetchProductDetailsSuccess(res.data.data));
      const body = {
        klevu_keywords: "",
        klevu_type: "clicked",
        klevu_productId: res.data.data.product_id,
        klevu_productGroupId: res.data.data.product_id,
        klevu_productVariantId: res.data.data.product_id,
        klevu_productName: res.data.data.name,
        klevu_productUrl: window.location.href,
        klevu_shopperIP: "0.0.0.0",
      };
      dispatch(analyticsProductTrack(body));
    })
    .catch((error) => {
      dispatch(fetchProductDetailsFailure({ error: error.data.message }));
    });
};

// fetch Related products list
export const fetchRelatedProductList = (payload) => {
  return {
    type: FETCH_RELATED_PRODUCT_LIST,
    payload: payload,
  };
};
export const fetchRelatedProductListSuccess = (payload) => {
  return {
    type: FETCH_RELATED_PRODUCT_LIST_SUCCESS,
    payload: payload,
  };
};
export const fetchRelatedProductListFailure = (payload) => {
  return {
    type: FETCH_RELATED_PRODUCT_LIST_FAILURE,
    payload: payload,
  };
};

export const getRelatedProductList = (body) => async (dispatch) => {
  dispatch(fetchRelatedProductList());
  axios
    .post("/AogGetRelatedProductsLists", body)
    .then((res) => {
      dispatch(fetchRelatedProductListSuccess(res.data.data));
    })
    .catch((error) => {
      dispatch(fetchRelatedProductListFailure({ error: error.data.message }));
    });
};

export const updateSortByValue = (payload) => {
  return {
    type: UPDATE_SORT_BY_VALUE,
    payload: payload,
  };
};
export const updatePageLimit = (payload) => {
  return {
    type: UPDATE_PAGE_LIMIT,
    payload: payload,
  };
};
export const updateIftCheckList = (payload) => {
  return {
    type: UPDATE_IFT_CHECK_LIST,
    payload: payload,
  };
};

export const updateDepartment = (payload) => {
  return {
    type: UPDATE_DEPARTMENT_NAME,
    payload: payload,
  };
};

export const emptyIftCheckList = (payload) => {
  return {
    type: EMPTY_IFT_CHECK_LIST,
    payload: payload,
  };
};

export const updateFromList = (payload) => {
  return {
    type: UPDATE_FROM_LIST,
    payload: payload,
  };
};
export const updatePastOrderList = (payload) => {
  return {
    type: UPDATE_PAST_ORDER_LIST,
    payload: payload,
  };
};

export const updateActivePage = (payload) => {
  return {
    type: UPDATE_ACTIVE_PAGE,
    payload: payload,
  };
};

export const updatePriceValue = (payload) => {
  return {
    type: UPDATE_PRICE_VALUE,
    payload: payload,
  };
};
export const updateNodeFuctionalText = (payload) => {
  return {
    type: UPDATE_NODE_FUNCTIONAL_TEXT,
    payload: payload,
  };
};
export const updateNodeFunctionalLevel = (payload) => {
  return {
    type: UPDATE_NODE_FUNCTIONAL_LEVEL,
    payload: payload,
  };
};
export const resetFilterSideBar = (payload) => {
  return {
    type: RESET_FILTER_SIDEBAR,
    payload: payload,
  };
};

export const saveCurrentDepartment = (payload) => {
  return {
    type: SAVE_CURRENT_DEPARTMENT,
    payload: payload,
  };
};

export const WeeklySalesSelected = (payload) => {
  return {
    type: WEEKLY_SALES_SELECTED,
    payload: payload,
  };
};

export const getProductsUPCWiseRequest = (payload) => {
  return {
    type: GET_PRODUCTS_UPC_WISE_REQUEST,
    payload: payload,
  };
};

export const getProductsUPCWiseSuccess = (payload) => {
  return {
    type: GET_PRODUCTS_UPC_WISE_SUCCESS,
    payload: payload,
  };
};

export const getProductsUPCWiseFailure = (payload) => {
  return {
    type: GET_PRODUCTS_UPC_WISE_FAILURE,
    payload: payload,
  };
};
export const getProductsUPCWise = (body) => async (dispatch) => {
  dispatch(getProductsUPCWiseRequest());
  axios
    .post("/AogGetProductsUPCWise", body)
    .then((res) => {
      if(res.data.status === "success"){
        dispatch(getProductsUPCWiseSuccess(res.data?.data));
      }
      else{
        dispatch(getProductsUPCWiseSuccess([]));
        dispatch(getProductsUPCWiseFailure(res.data?.message)); 
      }
    })
    .catch((error) => {
      dispatch(getProductsUPCWiseFailure({ error: error.data?.message }));
    });
};

// fetch Product details
export const fetchProductInfo = (payload) => {
  return {
    type: FETCH_PRODUCT_INFO,
    payload: payload,
  };
};
export const fetchProductInfoSuccess = (payload) => {
  return {
    type: FETCH_PRODUCT_INFO_SUCCESS,
    payload: payload,
  };
};
export const fetchProductInfoFailure = (payload) => {
  return {
    type: FETCH_PRODUCT_INFO_FAILURE,
    payload: payload,
  };
};

export const getProductInfo = (body) => async (dispatch) => {
  dispatch(fetchProductInfo());
  axios
    .post("/productinfo", body)
    .then((res) => {
      dispatch(fetchProductInfoSuccess(res.data?.data));
    })
    .catch((error) => {
      dispatch(fetchProductInfoFailure({ error: error.data?.message }));
    });
};
