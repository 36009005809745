import React, { useState } from "react";
import { useRef } from "react";
import DropdownListItem from "./DropdownListItem";
import downIcon from "assets/icons/down-black.svg";
import useOnClickOutside from "hooks/useOnClickOutside";

const Dropdown = ({
  optionList = [],
  onChange,
  icon,
  value,
  lable = "",
  defaultValue,
  placeholder,
  iconTag,
  extraText
}) => {
  const refDropdown = useRef();
  const [isShowDropdownOption, setIsShowDropdownOption] = useState(false);
  useOnClickOutside(refDropdown, () => setIsShowDropdownOption(false));

  const [selectedOptionValue, setselectedOptionValue] = useState(defaultValue);

  const handleChange = (value, title) => {
    setselectedOptionValue(value);
    onChange(value, title);
  };
  return (
    <div className="show-items" ref={refDropdown}>
      <button onClick={() => setIsShowDropdownOption(!isShowDropdownOption)}>
        {icon && <img src={icon} alt="Icon" />}
        {iconTag && iconTag}
        {placeholder && value == "" ? (
          <span>{placeholder}</span>
        ) : (
          <span>{`${lable} ${value}`} {extraText ? extraText : ""}</span>
        )}

        <img src={downIcon} alt="downIcon" />
      </button>

      <div
        className={
          isShowDropdownOption
            ? "dropdown-align dropdown-show"
            : "dropdown-align dropdown-hidden"
        }
      >
        <div className="list-menu">
          <ul>
            {optionList.map((dropdownOption, index) => {
              return (
                <DropdownListItem
                  optionName={dropdownOption.title}
                  optionValue={dropdownOption.value}
                  onSelectOption={handleChange}
                  key={index}
                  onClose={() => setIsShowDropdownOption(false)}
                  selectedOptionValue={selectedOptionValue}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
