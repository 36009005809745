import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import "./footer-links-theme-2.scss";
import AppStore from "assets/icons/app-store.svg";
import GooglePay from "assets/icons/google-pay.svg";
import AllPayment from "assets/icons/all-payment.svg";

// import discover from "assets/images/discover.png";
// import visa from "assets/images/visa.png";
// import pay1 from "assets/images/pay1.png";
// import wic from "assets/images/wic.jpg";
// import snap from "assets/images/snap.png";
// import amex from "assets/images/amex.png";
// import jcb from "assets/images/jcb.png";

const FooterLinksTheme2 = () => {
  const footerSectionData = useSelector(
    ({ common }) => common.commonDetailsData.footer_section
  );

  const supportData = useSelector(
    ({ common }) => common.commonDetailsData.header_section
  );
  const allowEcommerce = useSelector(
    ({ common }) => common.commonDetailsData.ecom_section.allow_ecommerce
  );

  return (
    <footer>
      <div className="container">
        <div className="grid">
          {supportData && (
            <div className="grid-items">
              <div className="footer-logo">
                {supportData.rsa_client_logo ? (
                  <NavLink to="/">
                    <img
                      src={supportData.rsa_client_logo}
                      alt={supportData.image_alt_text}
                    />
                  </NavLink>
                ) : (
                  ""
                )}
              </div>
              <div className="question">
                <p>
                  {supportData.support_question_title1
                    ? supportData.support_question_title1
                    : ""}
                </p>

                <a href={`tel:${supportData.support_contact_number}`}>
                  {" "}
                  {supportData.support_contact_number
                    ? supportData.support_contact_number
                    : ""}
                </a>
                {supportData.support_time ? (
                  <span>{supportData.support_time}</span>
                ) : (
                  ""
                )}
              </div>
              <div className="offer">
                {/* <p>
                  {supportData.support_question_title2
                    ? supportData.support_question_title2
                    : ""}
                  </p>*/}
                {supportData.support_email ? (
                  <a
                    href={`mailto:${supportData.support_email}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {supportData.support_email}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          )}

          {footerSectionData && (
            <div className="grid-items">
              <div className="sub-grid">
                <div className="sub-grid-items">
                  <h3>
                    {footerSectionData.my_account_section.title
                      ? footerSectionData.my_account_section.title
                      : ""}
                  </h3>
                  <ul>
                    {footerSectionData.my_account_section.pages &&
                      footerSectionData.my_account_section.pages
                        .filter((fls) => fls.has_visible === "1")
                        .map((el, index) => {
                          const url =
                            el.is_open_self === "0"
                              ? el.new_page_id === ""
                                ? el.url
                                : `/custom-pages/${el.new_page_id}`
                              : el.is_text === "1"
                                ? el.new_page_id === ""
                                  ? `/pages/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`
                                : el.new_page_id === ""
                                  ? `/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`;

                          const target =
                            el.is_open_self === "0" ? "" : "_blank";

                          return (
                            <li key={index}>
                              <a href={url} target={target} rel="noreferrer">
                                {el.name}
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="sub-grid-items">
                  <h3>
                    {footerSectionData.help_section.title
                      ? footerSectionData.help_section.title
                      : ""}
                  </h3>
                  <ul>
                    {footerSectionData.help_section.pages &&
                      footerSectionData.help_section.pages
                        .filter((fls) => fls.has_visible === "1")
                        .map((el, index) => {
                          const url =
                            el.is_open_self === "0"
                              ? el.new_page_id === ""
                                ? el.url
                                : `/custom-pages/${el.new_page_id}`
                              : el.is_text === "1"
                                ? el.new_page_id === ""
                                  ? `/pages/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`
                                : el.new_page_id === ""
                                  ? `/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`;

                          const target =
                            el.is_open_self === "0" ? "" : "_blank";
                          return (
                            <li key={index}>
                              <a href={url} target={target} rel="noreferrer">
                                {el.name}
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </div>
                <div className="sub-grid-items">
                  <h3>
                    {footerSectionData.company_section.title
                      ? footerSectionData.company_section.title
                      : ""}
                  </h3>
                  <ul>
                    {footerSectionData.company_section.pages &&
                      footerSectionData.company_section.pages
                        .filter((fls) => fls.has_visible === "1")
                        .map((el, index) => {
                          const url =
                            el.is_open_self === "0"
                              ? el.new_page_id === ""
                                ? el.url
                                : `/custom-pages/${el.new_page_id}`
                              : el.is_text === "1"
                                ? el.new_page_id === ""
                                  ? `/pages/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`
                                : el.new_page_id === ""
                                  ? `/${el.url}`
                                  : `/custom-pages/${el.new_page_id}`;

                          const target =
                            el.is_open_self === "0" ? "" : "_blank";
                          return (
                            <li key={index}>
                              <a href={url} target={target} rel="noreferrer">
                                {el.name}
                              </a>
                            </li>
                          );
                        })}
                  </ul>
                </div>

                {footerSectionData?.shopping_services_section?.title && footerSectionData.shopping_services_section.pages.length > 0 &&
                  <div className="sub-grid-items">
                    <h3> {footerSectionData.shopping_services_section.title
                      ? footerSectionData.shopping_services_section.title
                      : ""}</h3>
                    <ul>
                      {footerSectionData.shopping_services_section.pages &&
                        footerSectionData.shopping_services_section.pages
                          .filter((fls) => fls.has_visible === "1")
                          .map((el, index) => {
                            const url =
                              el.is_open_self === "0"
                                ? el.new_page_id === ""
                                  ? el.url
                                  : `/custom-pages/${el.new_page_id}`
                                : el.is_text === "1"
                                  ? el.new_page_id === ""
                                    ? `/pages/${el.url}`
                                    : `/custom-pages/${el.new_page_id}`
                                  : el.new_page_id === ""
                                    ? `/${el.url}`
                                    : `/custom-pages/${el.new_page_id}`;

                            const target =
                              el.is_open_self === "0" ? "" : "_blank";
                            return (
                              <li key={index}>
                                <a href={url} target={target} rel="noreferrer">
                                  {el.name}
                                </a>
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                }


              </div>
            </div>
          )}

          {/* <div className="grid-items">
            {supportData.button_show_download_our_app === '1'? (
                <>
                  <h3 className="app-title">Download App</h3>
                  <div className="app-align">
                    {supportData.ios_app_url && (
                      <a
                        target="_blank"
                        href={supportData.ios_app_url}
                        rel="noreferrer"
                      >
                        <img src={AppStore} alt="AppStore" />
                      </a>
                    )}
                    {supportData.android_app_url && (
                      <a
                        href={supportData.android_app_url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={GooglePay} alt="GooglePay" />
                      </a>
                    )}
                  </div>
                </>
            ):("")} */}
          {/* <div className="payment">
              {allowEcommerce == '1' ? (
                <>
                  <h4>Payment Method</h4>
                  <div
                    className="preview"
                    style={{ display: "inline-block", margin: "0 8px 0 0" }}
                  >
                    {footerSectionData.accept_card_details.accept_discover == "1" ?
                      (<img
                        src={discover}
                        alt="discover"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_visa == "1" ?
                      (<img
                        src={visa}
                        alt="visa"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_mastercard == "1" ?
                      (<img
                        src={pay1}
                        alt="pay1"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_amex == "1" ?
                      (<img
                        src={amex}
                        alt="amex"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_jcb == "1" ?
                      (<img
                        src={jcb}
                        alt="jcb"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_wic == "1" ?
                      (<img
                        src={wic}
                        alt="wic"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                    {footerSectionData.accept_card_details.accept_snap == "1" ?
                      (<img
                        src={snap}
                        alt="snap"
                        style={{ height: "24px", width: "40px" }}
                      />) : ""}
                  </div>
                </>                
              ) : ""}
              
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </footer >
  );
};
export default FooterLinksTheme2;
