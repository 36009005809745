import React from "react";
import ProductImage from "../../../assets/images/preview-product.png";
import "./reward-details-item.scss";
import { Link } from "react-router-dom";

const RewardDetailsItem = ({
  eachRewardTier,
  LM_Reward_ID,
  Redeem,
  index,
  selectedRedeem,
  setSelectedRedeem,
}) => {
  const LM_Reward_Tier_ID = eachRewardTier?.LMRewardTierID;

  const handleSelectedRedeem = (value, point, tierId, rewardId) => {
    if (selectedRedeem.tierId === tierId) {
      setSelectedRedeem({
        couponValue: null,
        pointsRequired: null,
        tierId: null,
        couponTypeId: null,
      });
    } else {
      setSelectedRedeem({
        value: value,
        point: point,
        tierId: tierId,
        rewardId: rewardId,
      });
    }
  };

  return (
    <>
      <div className="reward-details-main-wrap">
        {Redeem && (
          <div className="product-checkbox">
            {eachRewardTier?.IsItQualifiedforReward ? (
              <>
                <input
                  type="checkbox"
                  id={`${eachRewardTier.TierTitle}${index}`}
                  onChange={() =>
                    handleSelectedRedeem(
                      eachRewardTier.CouponValue,
                      eachRewardTier.PointsRequired,
                      eachRewardTier.LMRewardTierID,
                      eachRewardTier.TierRewardCouponTypeId
                    )
                  }
                  checked={
                    selectedRedeem.tierId === eachRewardTier.LMRewardTierID
                  }
                />
                <label for={`${eachRewardTier.TierTitle}${index}`}></label>
              </>
            ) : (
              ""
            )}
          </div>
        )}

         {/* add select conditions */}
         <div
              className={`reward-details-box ${
                eachRewardTier?.IsItQualifiedforReward &&
                selectedRedeem.tierId === eachRewardTier.LMRewardTierID
                  ? "checked"
                  : ""
              }`}
              onClick={() => {
                if (eachRewardTier?.IsItQualifiedforReward) {
                  handleSelectedRedeem(
                    eachRewardTier.CouponValue,
                    eachRewardTier.PointsRequired,
                    eachRewardTier.LMRewardTierID,
                    eachRewardTier.TierRewardCouponTypeId
                  );
                }
              }}
            >
          <div className="reward-details-img">
            <img src={eachRewardTier?.TierImageUrl} alt="product_image" />
          </div>
          <div className="reward-detail-items">
            <div className="reward-details-content-wrapper">
              <div className="reward-details-point">
                <span>{eachRewardTier?.PointsRequiredString}</span>
              </div>
              <div className="reward-details-title-btn">
                <div className="reward-details-title">
                  {eachRewardTier?.TierTitle}
                </div>
              </div>
            </div>
            <div className="reward-details-range-bar">
              {eachRewardTier?.TierRewardCouponTypeId == 2 && (
                <div className="reward-btn">
                  <Link
                    to="/rewardviewdetails"
                    state={{
                      LM_Reward_ID: LM_Reward_ID,
                      LM_Reward_Tier_ID: LM_Reward_Tier_ID,
                      eachRewardTier: eachRewardTier,
                    }}
                  >
                    View Items
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RewardDetailsItem;
