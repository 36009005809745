import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  getRemoveProductToCartList,
  editProductToCartList,
} from "redux/actions";
import Dropdown from "components/Common/Dropdown";
import { debounce } from 'lodash';
import { useRef } from "react";
import { getLocalStorageValue } from 'config/helper';

const CartProduct = ({ product }) => {
  const {
    image,
    name,
    qty = 1,
    final_price,
    product_id,
    item_id,
    final_total,
    custom_options_select,
    custom_options_arr,
    product_note,
    sku,
    ebt_image,
    ebt_item
  } = product;

  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(+qty);
  const [updateProductNote, setUpdateProductNote] = useState(
    product_note ?? ""
  );
  const { currentStoreID } = useSelector(({ store }) => store);
  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);

  const clientId = getLocalStorageValue("RSAclient-id");
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");

  const [selectDropDownedOption, setSelectDropDownedOption] = useState();
  const dropdownCustomOptions =
    custom_options_select &&
    custom_options_select.find((product) => product.type === "dropdown");

  useEffect(() => {
    if (custom_options_arr[0])
      setSelectDropDownedOption(custom_options_arr[0].value)
    else {
      const defaultOption = dropdownCustomOptions.values.find((list) => list.is_default === "1");
      setSelectDropDownedOption(defaultOption?.value || "");
    }
  }, []);



  const selectedCustomOption =
    dropdownCustomOptions &&
    dropdownCustomOptions.values.filter((value) => value.value === selectDropDownedOption);

  const handleChangeDropDownOption = (_, name) => {
    const selectedOption =
      dropdownCustomOptions && dropdownCustomOptions.values?.filter((value) => value.value === name);
    setSelectDropDownedOption(name);

    handleUpdateProductToCart(
      productQuantity,
      updateProductNote,
      selectedOption
    );
  };

  const handleUpdateProductToCart = (
    quantity,
    product_note,
    selectedOption
  ) => {

    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      product_id: product_id,
      item_id: item_id,
      qty: quantity,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : "",
      product_note,
      page_name: 'my_cart',
      custom_options_arr: selectedOption || [],
    };
    dispatch(editProductToCartList(body));
  };

  const handleRemoveProductToCart = (item_id) => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      item_id,
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    dispatch(getRemoveProductToCartList(body));
  };

  const handleIncrementCount = () => {
    setProductQuantity(productQuantity + 1);
    handleUpdateProductToCart(productQuantity + 1, updateProductNote, selectedCustomOption);
  };
  const handleDecrementCount = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
      handleUpdateProductToCart(productQuantity - 1, updateProductNote, selectedCustomOption);
    }

    return productQuantity;
  };

  const handleChangeProductNote = (value) => {
    setUpdateProductNote(value);
    updateProductNoteResult.current(value, selectedCustomOption);
  }

  const updateProductNoteResult = useRef(
    debounce((value, selectedCustomOption) => {
      handleUpdateProductToCart(productQuantity, value, selectedCustomOption);
    }, 500)
  );

  return (
    <div className="cart-items-list-view-section" key={product_id}>
      <div className="sub-grid">
        <div className="sub-grid-items">
          <div className="product-view-grid">
            <div className="product-view-grid-items">
              <div className="product-image-show">
                <img src={image} alt="ProductImage" />
              </div>
            </div>
            <div className="product-view-grid-items">
              <div className="title-icon-mobile-show">
                <div className="mobile-view-title-icon-align">
                  <span></span>
                  <div className="remove-icon" onClick={() => handleRemoveProductToCart(item_id)} >
                    <i className="fa-solid fa-trash-can"></i>
                  </div>
                </div>
              </div>

              <Link
                to={product.is_mealkit == "0" ? (`/product-description/${product.department_slug}/${product.slug}`) : (`/catering-product-description/${product.department_slug}/${product.slug}`)}
              >
                <h6>{name}</h6>
              </Link>
              <p className="product-sku">
                UPC: &nbsp;<span> {" "} {sku}</span>
              </p>

              <h5>By Best Choice</h5>
              <font>${final_total}</font>
              {ebt_item === "1" && (
                <img src={ebt_image} alt="ebt_image" />
              )}
              {/* 
      <div className="rating-alignment">
        <div className="star">
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
          <i className="fa-solid fa-star"></i>
        </div>
        <div className="count">
          <span>(4.0)</span>
        </div>
      </div> */}
              <div className="mobile-view-show">
                <div className="mobiel-quantity-button">
                  <button>
                    <span>{productQuantity}</span>
                    <div className="arrow-alignment">
                      <div onClick={handleIncrementCount}>
                        <i className="fa-solid fa-angle-up"></i>
                      </div>
                      <div onClick={handleDecrementCount}>
                        <i className="fa-solid fa-angle-down"></i>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="sub-grid-items">
          <div className="all-action-alignment">
            <div className="price-count">
              <span>${final_price}</span>
            </div>
            <div className="quantity-button">
              <button>
                <span>{productQuantity}</span>
                <div className="arrow-alignment">
                  <div onClick={handleIncrementCount}>
                    <i className="fa-solid fa-angle-up"></i>
                  </div>
                  <div onClick={handleDecrementCount}>
                    <i className="fa-solid fa-angle-down"></i>
                  </div>
                </div>
              </button>
            </div>
            <div className="price-count">
              <span>${final_total}</span>
            </div>
            <div
              className="remove-icon"
              onClick={() => handleRemoveProductToCart(item_id)}
            >
              <i className="fa-solid fa-trash-can"></i>
            </div>
          </div>
        </div>
      </div>
      <div className="allow-substitition-grid">
        <div className="grid-items">
          {custom_options_select &&
            custom_options_select.map((option) => {
              if (option.values && !!option.values.length) {

                return <div className="allow-substitition-grid-items" key={option.id}>
                  <span>{option.title}</span>
                  <Dropdown
                    optionList={
                      option.values
                        ? option.values.map((list) => {
                          return { title: list.value, value: list.id };
                        })
                        : []
                    }
                    lable=""
                    value={selectDropDownedOption}
                    placeholder="Select Option"
                    defaultValue=""
                    onChange={handleChangeDropDownOption}
                  />
                </div>
              } else {
                return <div className="allow-substitition-grid-items" ></div>
              }

            })}
        </div>

        <div className="allow-substitition-grid-items">
          <div className="textarea-style">
            <textarea
              onChange={(e) => {
                handleChangeProductNote(e.target.value);
              }}
              placeholder="Enter item notes or substitution notes..."
              value={updateProductNote}
            ></textarea>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
