import React, { useState, useEffect } from "react";
import "./product-info-theme2.scss";
const ProductInfoTheme2 = ({ productDetails }) => {
  const { nutrition_description, nutrition_image, description } =
    productDetails;
  const [selectedAttribute, setSelectedAttribute] = useState("description-tab");
  useEffect(() => {
    setSelectedAttribute(
      description
        ? "description-tab"
        : nutrition_description
          ? "nutrition-tab"
          : ""
    );
  }, [productDetails]); // eslint-disable-line

  // product desc toggles
  const nutritionImage = productDetails?.Images?.find(image => image["Nutrition Facts Label Image"])?.["Nutrition Facts Label Image"] || null; // eslint-disable-line
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleDescription = () => { setIsExpanded(!isExpanded); };

  const [isNutritionExpanded, setIsNutritionExpanded] = useState(false);
  const toggleNutrition = () => setIsNutritionExpanded(!isNutritionExpanded);

  const [isInfoExpanded, setIsInfoExpanded] = useState(false);
  const toggleInfo = () => setIsInfoExpanded(!isInfoExpanded);

  const [isInstExpanded, setIsInstExpanded] = useState(false);
  const toggleInst = () => setIsInstExpanded(!isInstExpanded);

  return (
    <>
      <div className="product-info-theme2">
        <div className="container">
          <div className="description-main-wrap">
            {productDetails?.["Product Details"] && (
              <div className="description-container mb-20">
                <h2>Description</h2>
                <div className={`description-text ${isExpanded ? 'expanded' : 'collapsed'}`}>
                  <span>{productDetails?.["Product Details"]}</span>
                </div>
                <div className="view-item-btn">
                  <button className="toggle-btn" onClick={toggleDescription}>
                    {isExpanded ? (
                      <>
                        View Less<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                          <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                            d="M13 30L25 18L37 30" />
                        </svg>
                      </>
                    ) : (
                      <>
                        View All<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                          <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                            d="M36 18L24 30L12 18" />
                        </svg>
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}
            {productDetails?.["Legacy Gladson Category"] && (
              <div className="highlights-container mb-20">
                <h2>Highlights</h2>
                <div className="button-container">
                  {productDetails?.["Legacy Gladson Category"].split("|").map((item, index) => (
                    <div className="highlight-btn" key={index}>
                      <span className="btn-text">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {nutritionImage && (
              <div className="nutrition-ingredients-section mb-20">
                <div className="box nutrition-box">
                  <div className="box-header" onClick={toggleNutrition}>
                    <h2>Nutrition and Ingredients</h2>
                    {isNutritionExpanded ? (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M36 18L24 30L12 18" />
                      </svg>
                    ) : (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M13 30L25 18L37 30" />
                      </svg>
                    )}
                  </div>
                  {isNutritionExpanded && (
                    <div className="box-content">
                      <div className="grid-box">
                        <div className="grid-main-box">
                          <h4 className="grid-title">Nutrition Facts</h4>
                          <div>
                            <img src={nutritionImage} alt="Nutrition Facts" />
                          </div>

                        </div>
                        {productDetails?.["Ingredients (Concatenated)"] && (
                          <div className="grid-main-box">
                            <h4 className="grid-title">Ingredients</h4>
                            {productDetails?.["Ingredients (Concatenated)"].split(",").map((ingredient, index) => (
                              <>
                                <span key={index}>{ingredient}</span> <br />
                              </>
                            ))}
                          </div>
                        )}
                      </div>
                      {productDetails?.["Warning"] && (
                        <div className="grid-main-box notice-warning">
                          <h4 className="grid-title">Allergens and safety warnings</h4>
                          <span>{productDetails?.["Warning"]}</span>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}
            {productDetails?.["Directions (Concatenated)"] && (
              <div className="desc-information-section mb-20">
                <div className="box nutrition-box">
                  <div className="box-header" onClick={toggleInst}>
                    <h2>Instructions</h2>
                    {isInstExpanded ? (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M36 18L24 30L12 18" />
                      </svg>
                    ) : (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M13 30L25 18L37 30" />
                      </svg>
                    )}
                  </div>
                  {isInstExpanded && (
                    <div className="info-desc">
                      {productDetails?.["Directions (Concatenated)"].split(".").map((direction, index) => (
                        <>
                          <span key={index}>{direction}</span> <br />
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
            {productDetails?.["Warnings (Concatenated)"] && (
              <div className="desc-information-section mb-20">
                <div className="box nutrition-box">
                  <div className="box-header" onClick={toggleInfo}>
                    <h2>More information</h2>
                    {isInfoExpanded ? (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M36 18L24 30L12 18" />
                      </svg>
                    ) : (
                      <svg className="expand-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 48 48">
                        <path fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"
                          d="M13 30L25 18L37 30" />
                      </svg>
                    )}
                  </div>
                  {isInfoExpanded && (
                    <div className="info-desc">
                      <h4 className="grid-title">Disclaimer</h4>
                      {productDetails?.["Warnings (Concatenated)"].split(".").map((warning, index) => (
                        <>
                          <span key={index}>{warning}</span> <br />
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ProductInfoTheme2;
