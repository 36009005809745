import React, { useCallback, useEffect, useState } from "react";
import Breadcrumb from "components/Common/Breadcrumb";
import ProductInfoTheme2 from "./ProductInfoTheme2";
import ProductPreviewTheme2 from "./ProductPreviewTheme2";
import RelatedProductTheme2 from "./RelatedProductTheme2";
import { getProductDetails, getProductInfo, getDepartmentTree, getRelatedProductList } from "redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getLocalStorageValue } from 'config/helper';
import LoadingImage from "../../assets/icons/load.gif";

const ProductDescriptionTheme2 = () => {
  const dispatch = useDispatch();
  const { departmentSlug, productSlug } = useParams();
  const clientId = getLocalStorageValue("RSAclient-id");

  const { productDetails, departmentTree, relatedProductList, productInfo, productInfoLoading, productDetailsLoading } = useSelector(
    ({ product }) => product
  );

  const isUserLoggedIn = useSelector(({ user }) => user.isUserLoggedIn);
  const userToken = getLocalStorageValue("user-token");
  const memberNumber = getLocalStorageValue("member-number");
  const { currentStoreID } = useSelector(({ store }) => store);
  const storeId = currentStoreID || getLocalStorageValue("selected-store-id");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 769);

  const department =
    departmentTree &&
    departmentTree.find((slug) => slug.slug === departmentSlug);

  useEffect(() => {
    const body = {
      RSAClientId: clientId,
      ClientStoreId: +storeId || 1,
      search: "",
      AppName: isUserLoggedIn ? "shop" : "",
      member_number: isUserLoggedIn ? memberNumber : "",
      user_token: isUserLoggedIn ? userToken : "",
      Version: isUserLoggedIn ? "1" : "",
      DeviceType: isUserLoggedIn ? "web" : ""
    };
    if (!departmentTree.length) {
      dispatch(getDepartmentTree(body));
    }
    dispatch(getProductDetails({ ...body, slug: productSlug }));
  }, [storeId, productSlug]); // eslint-disable-line

  useEffect(() => {
    if (productDetails) {
      const body = {
        RSAClientId: clientId,
        ClientStoreId: +storeId || 1,
        slug: productSlug,
        category_ids: productDetails.category_ids,
        AppName: isUserLoggedIn ? "shop" : "",
        member_number: isUserLoggedIn ? memberNumber : "",
        user_token: isUserLoggedIn ? userToken : "",
        Version: isUserLoggedIn ? "1" : "",
        DeviceType: isUserLoggedIn ? "web" : "",
        category_name: department?.name ?? ""
      };
      dispatch(getRelatedProductList(body));
      if (productSlug === productDetails.slug && productDetails.sku) {
        dispatch(getProductInfo({ RSAClientId: clientId, upc: productDetails.sku }));
      }
    }
  }, [productDetails, department, storeId]); // eslint-disable-line

  const breadcrumbList = [
    {
      lable: department && department.name,
      link: `/departments/${departmentSlug}`,
      searchTag: "",
    },
    {
      lable: productDetails && productDetails.name,
      link: "",
      searchTag: "",
    },
  ];

  const checkViewportWidth = useCallback(() => {
    setIsMobile(window.innerWidth < 769);
  }, []);

  useEffect(() => {
    checkViewportWidth();
    window.addEventListener('resize', checkViewportWidth);
    return () => {
      window.removeEventListener('resize', checkViewportWidth);
    };
  }, [checkViewportWidth]);

  return (
    <>
      {department ? (
        <Breadcrumb breadcrumbList={breadcrumbList} />
      ) : (
        <div className="breadcrumb"></div>
      )}
      {productDetails && !productInfoLoading && (
        <>
          <ProductPreviewTheme2 productDetails={productDetails} productInfo={productInfo} />
          {productInfo && !productInfoLoading && (
            <ProductInfoTheme2 productDetails={productInfo} />
          )}
          {relatedProductList && relatedProductList.length && (
            <RelatedProductTheme2 relatedProductList={relatedProductList} />
          )}
        </>
      )}
      {(productDetailsLoading || productInfoLoading) && (
        <div className="load-icon">
        <img src={LoadingImage} alt="Loading..." />
      </div>
      )}
      
    </>
  );
};
export default ProductDescriptionTheme2;
